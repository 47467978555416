<template>
  <Row id="Settings">
    <Col :span="24" class="title">
    <TypographyTitle>{{ $t('nav.name.SettingsManage') }}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
    <Tabs v-model:activeKey="activeKey">
      <!-- <TabPane key="1" :tab="$t('config.name.base_config')">
                <Form :model="data" ref="_form" class="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 9 }" :validate-messages="validateMessages">
                    <Row :gutter="[16,16]">
                       
                        <Col :xs="24" :sm="24">
                        <FormItem :label="$t('config.field.site_name')" :name="['name']" :rules="[{ required: true}]">
                            <Input :placeholder="$t('config.field.site_name')" v-model:value="data.name"></Input>
                        </FormItem>
                        </Col>
                        <Col :xs="24" :sm="24">
                        <FormItem :label="$t('config.field.time_zone')">
                            <Input :placeholder="$t('config.field.time_zone')" v-model:value="data.zone"></Input>
                        </FormItem>
                        </Col>
                        <Col :xs="24" :sm="24">
                        <FormItem :label="$t('config.field.logo')" :rules="[{ required: false}]">
                            <Input :placeholder="$t('config.field.logo')" v-model:value="data.logo"></Input>
                        </FormItem>
                        </Col>
                        <Col :xs="24" :sm="24">
                        <FormItem :label="$t('config.field.copyright')" :rules="[{ required: false}]">
                            <Input :placeholder="$t('config.field.copyright')" v-model:value="data.copyright"></Input>
                        </FormItem>
                        </Col>
                        <Col :xs="24" :sm="24">
                        <FormItem label=" " class="not-label">
                            <Button type="primary" @click="send">Save</Button>
                        </FormItem>
                        </Col>
                        
                    </Row>
                </Form>
            </TabPane> -->
      <TabPane key="2" :tab="$t('config.name.email_setting')" force-render>
        <Form :model="email" ref="_sendEmail" class="form" :rules="validateRules">
          <Row :gutter="[16, 16]">

            <Col :xs="12" :sm="12">
            <FormItem :label="$t('config.field.host')" name="host">
              <Input :placeholder="$t('config.field.host')" v-model:value="email.host"></Input>
            </FormItem>
            </Col>
            <Col :xs="12" :sm="12">
            </Col>
            <Col :xs="12" :sm="12">
            <FormItem :label="$t('config.field.username')" name="username">
              <Input :placeholder="$t('config.field.username')" v-model:value="email.username"></Input>
            </FormItem>
            </Col>
            <Col :xs="12" :sm="12">
            </Col>
            <Col :xs="12" :sm="12">
            <FormItem :label="$t('config.field.password')" name="password">
              <Input :placeholder="$t('config.field.password')" v-model:value="email.password"></Input>
            </FormItem>
            </Col>
            <Col :xs="12" :sm="12">
            </Col>
            <Col :xs="12" :sm="12">
            <FormItem :label="$t('config.field.sender')" name="sender">
              <Input :placeholder="$t('config.field.sender')" v-model:value="email.sender"></Input>
            </FormItem>
            </Col>
            <Col :xs="12" :sm="12">
            </Col>
            <Col :xs="24" :sm="24" style="text-align: center;">
            <Button type="primary" @click="setEmailInfo">Save</Button>
            </Col>

          </Row>
        </Form>
      </TabPane>
      <TabPane key="3" :tab="$t('config.name.logo')">
        <Form :model="logo" ref="_logo" class="form" :rules="validateRules">
          <Row :gutter="[16, 16]">
            <Col :xs="12" :sm="12">
            <FormItem :label="$t('config.field.logo')" name="url">
              <UploadImageBase :url="logo.url"
                                   @new_image_url="(value) => {
                                      this.logo.url = value
                                   }"
                                   ref="upload_logoUrl"
                      />
            </FormItem>
            </Col>
            
            <Col :xs="24" :sm="24" style="text-align: center;">
            <Button type="primary" @click="setLogo">Save</Button>
            </Col>

          </Row>
        </Form>
      </TabPane>
      <!-- <TabPane key="3" tab="Tab 3">Content of Tab Pane 3</TabPane> -->
    </Tabs>
    </Col>
  </Row>
</template>

<script>
import { ref } from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message, Tabs, TabPane } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { SettingsPut } from "@/api/SettingsPut"
import { SettingsGet } from "@/api/SettingsGet"
import UploadImageBase from "@/components/Common/UploadImageBase"

export default {
  name: "SettingsManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    Tabs,
    UploadImageBase,
    TabPane
  },
  data() {
    return {
      activeKey: ref('2'),
      email: {
        sender: '',
        host: '',
        username: '',
        password: '',
      },
      logo:{
        url: '',
      },
      validateRules: {
        host: [
          {
            required: true,
            message: this.$t("config.validate.required.host"),
            trigger: "blur",
          },
        ],
        username: [
          {
            required: true,
            message: this.$t("config.validate.required.username"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("config.validate.required.password"),
            trigger: "blur",
          },
        ],
        sender: [
          {
            required: true,
            message: this.$t("config.validate.required.sender"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    setEmailInfo() {
      this.$refs["_sendEmail"]
        .validate()
        .then(() => {
          const api = new SettingsPut();
          api.post({
            name: "host",
            content: this.email.host
          }).then((response) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
          api.post({
            name: "password",
            content: this.email.password
          }).then((response) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
          api.post({
            name: "username",
            content: this.email.username
          }).then((response) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
          api.post({
            name: "sender",
            content: this.email.sender
          }).then((response) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });



        })
        .catch();
    },
    setLogo() {
      this.$refs["_logo"]
        .validate()
        .then(() => {
          const api = new SettingsPut();
          console.log("logo:");
          console.log(this.logo.url);
          api.post({
            name: "logo",
            content: this.logo.url
          }).then((response) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch();
    },
    getHost() {
      const api = new SettingsGet();
      api.get({
        name: "host"
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.email.host = response.data.data.content
        } else {
          this.email.host = ""
        }
      });
    },
    getUsername() {
      const api = new SettingsGet();
      api.get({
        name: "username"
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.email.username = response.data.data.content
        } else {
          this.email.username = ""
        }
      });
    },
    getPassword() {
      const api = new SettingsGet();
      api.get({
        name: "password"
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.email.password = response.data.data.content
        } else {
          this.email.password = ""
        }
      });
    },
    getSender() {
      const api = new SettingsGet();
      api.get({
        name: "sender"
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.email.sender = response.data.data.content
        } else {
          this.email.sender = ""
        }
      });
    },
    getLogo() {
      const api = new SettingsGet();
      api.get({
        name: "logo"
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.logo.url = response.data.data.content
        } else {
          this.logo.url = ""
        }
      });
    },
  },
  mounted() {
    this.getHost();
    this.getUsername();
    this.getPassword();
    this.getSender();
    this.getLogo();
  },
}
</script>

<style lang="less">
@import url("~@/assets/less/home/settings.less");
</style>