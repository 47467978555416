<template>
  <Modal
    id="EmployeeEntryRecordEdit"
    width="40%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="employeeEntryRecordForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10" v-if="actionType === 'reinstated'">
                    <FormItem :label="$t('employeeEntryRecord.field.entry_date')" name="entryDate">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.entryDate" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'quit'">
                    <FormItem :label="$t('employeeEntryRecord.field.term_date')" name="termDate">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.termDate" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'quit'">
                    <FormItem :label="$t('employee.field.remark')" name="remark">
                      <Textarea
                              v-model:value="form.remark"
                              :placeholder="$t('employee.validate.required.remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { EmployeeQuit } from "@/api/EmployeeQuit"
import { EmployeeReinstated } from "@/api/EmployeeReinstated"


export default {
  name: "EmployeeEntryRecordEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idEmpEntRec : "",
        idEmp : "",
        entryDate : "",
        termDate : "",
      },
      fileList: {
      },
      selectList: {
      },
      validateRules: {
        idEmpEntRec: [
          {
            required: true,
            message: this.$t("employeeEntryRecord.validate.required.id_emp_ent_rec"),
            trigger: "change",
          },
        ],
        idEmp: [
          {
            required: true,
            message: this.$t("employeeEntryRecord.validate.required.id_emp"),
            trigger: "change",
          },
        ],
        entryDate: [
          {
            required: true,
            message: this.$t("employeeEntryRecord.validate.required.entry_date"),
            trigger: "change",
          },
        ],
        termDate: [
          {
            required: true,
            message: this.$t("employeeEntryRecord.validate.required.term_date"),
            trigger: "change",
          },
        ],
        remark: [
          {
            required: true,
            message: this.$t("employeeEntryRecord.validate.required.term_remark"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "reinstated":
          name = this.$t("public.name.add");
          break;
        case "quit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    reinstatedShow(idEmp) {
      this.actionType = "reinstated";
      this._resetData();
      this.form.idEmp = idEmp;
      this.visible = true;
    },
    async quitShow(idEmp) {
      this._resetData();
      this.actionType = "quit";
      this.form.idEmp = idEmp;
      this.visible = true;
    },
    submit() {
      this.$refs["employeeEntryRecordForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "reinstated":
              console.log("reinstated");
              api = new EmployeeReinstated();
              api.post({
                idEmp: this.form.idEmp,
                entryDate: this.form.entryDate
              }).then((response, any) => {
                if (0 == parseInt(response.data.code)) {
                  message.success(response.data.msg);
                  this.$emit("updateList");
                  this.visible = false;
                } else {
                  message.error(response.data.msg);
                }
              });
              break;
            case "quit":
              console.log("quit");
              api = new EmployeeQuit();
              api.post({
                idEmp: this.form.idEmp,
                termDate: this.form.termDate,
                remark: this.form.remark,
              }).then((response, any) => {
                if (0 == parseInt(response.data.code)) {
                  message.success(response.data.msg);
                  this.$emit("updateList");
                  this.visible = false;
                } else {
                  message.error(response.data.msg);
                }
              });
              break;
            default:
              return false;
          }
          
        })
        .catch(() => {
        });
    },
    // reset data
    _resetData() {
      this.form = {
        idEmpEntRec : "",
        idEmp : "",
        entryDate : "",
        termDate : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>