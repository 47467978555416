const zhTW = {
    public: {
        name: {
            status: "狀態",
            login: "登錄",
            logout: "登出",
            hello: "你好",
            username: "用戶名",
            account: "賬號",
            email: "E-mail",
            password: "密碼",
            confirm_password: "確認密碼",
            reset_password: "重置密碼",
            captcha: "驗證碼",
            more: "更多",
            add: "添加",
            edit: "編輯",
            del: "刪除",
            detail: "詳情",
            yes: "是",
            no: "否",
            empty: "空",
            are_you_sure_delete: "你確定需要刪除該信息嗎",
            action_cannot_resumed: "該操作將無法恢復",
            search: "搜索",
            reset: "重置",
            spread: "展開",
            retract: "收回",
            show: "展示",
            no_page: "無頁面",
            service: "服務商",
            phone_country_code: "電話國家代碼",
            name: "名稱",
            phone: "電話",
            sex: "性別",
            country: "國家",
            address: "地址",
            birthday: "出生年月日",
            remark: "備注",
            personal: "個人信息",
            enclosure: "附件",
            logo: "Logo",
            confirm: "確認",
            send: "發送",
            export: "導出",
            no_permission: "沒有權限",
            select_none: "不選",
            no_data: "沒有數據！"
        },
        language: {
            zhTW: "中文（繁體）",
            enUS: "英文",
            zhCN: "中文（簡體）"
        },
        status: {
            0: "未知",
            1: "正常",
            2: "禁用",
            3: "已删除",
        },
        sex: {
            1: "男",
            2: "女",
            3: "未知"
        },
        placeholder: {
            please_select: "請選擇",
        },
        table: {
            name: "名稱",
            status: "狀態",
            action: "操作",
            content: "內容",
            created_at: "創建時間",
            image: "圖像",
            email: "E-mail",
            order_id: "排序",
            sex: "性別",
            last_ip: "最後登錄IP",
            phone: "電話",
        },
        field: {
            name: "名稱",
            username: "用戶名",
            content: "內容",
            phone_country_code: "電話國家代碼",
            phone: "電話",
            email: "E-mail",
            order_id: "排序",
            portrait: "頭像",
            status: "狀態",
            image: "圖像",
            sex: "性別",
        },
        validate: {
            required: {
                id: "缺少ID",
                username: "請輸入用戶名",
                password: "請輸入密碼",
                repassword: "請重復密碼",
                name: "請輸入名稱",
                phone: "請輸入電話",
                email: "請輸入E-mail",
                portrait: "請上傳頭像",
                phone_country_code: "請選擇電話國家代碼",
            },
            tips: {
                inconsistent_password: "密碼不一致",
                length_should: "長度應爲{min}至{max}"
            }
        }
    },
    language: {
        zhTW: "中文（繁體）",
        enUS: "英語",
        zhCN: "中文（簡體）"
    },
    nav: {
        name: {
            Dashboard: "儀錶盤",
            AnnualLeavePlanManage: "年假計劃管理",
            AttendanceManage: "考勤管理",
            AttendanceLogsManage: "考勤記錄管理",
            AuthorityManage: "權限管理",
            DepartmentManage: "部門管理",
            EmployeeManage: "員工管理",
            Settings: "系統設定",
            EmployeeEntryRecordManage: "員工入職時間管理",
            HolidayManage: "公眾假期管理",
            LeaveManage: "請假管理",
            LeaveTypeManage: "請假類型管理",
            SelfLeaveManage: "請假",
            MpfSettingManage: "強積金設定管理",
            OvertimeApplicationManage: "加班申請管理",
            SelfOvertimeApplicationManage: "申請加班",
            RemainingVacationManage: "剩餘假期管理",
            ReportManage: "薪金管理",
            RoleManage: "職位管理",
            SalaryInformationManage: "工資信息管理",
            SettingsManage: "系統設定管理",
            EmployeeModel: "員工",
            AttendanceModel: "考勤",
            ReportModel: "報告",
            RoleModel: "職位",
            SalaryModel: "薪金",
            CifManage: "公司和用户资料（CIF 和入职）",
            PavementManage: "路面管理",
            RoboticControManage: "机器人控制管理",
            RemoteOperationManage: "通过 5G 网络远程操作",
            ConeDispenseCollectManage: "锥形分配和收集",
            TunnelWashingManage: "隧道清洗",
            PlantsCroppingManage: "植物种植",
            RoadMarkingManage: "道路标记",
            TestingEquationManage: "测试方程/公式库",
            UtilityManage: "工具",
            MaterialCategoriesManage: "物料类别和中央仓库",
            MobileDeviceManage: "移动设备管理 (IoT)",
            ReportingManage: "报告管理",
            IdentityManage: "身份和访问管理 ",
            RoadStudManage: "路钉管理",
            NamiResearchedManage: "Nami研究数据",
            AnalyticsCombinationManage: "分析组合",
            AIBankManage: "人工智能 银行+人工智能 训练",
            TestingMaterialManage: "测试材料指标",
            TestingMachineManage: "试验机管理",
            LoraWanManage: "LoraWan 数据采集管理",
        },
        table: {
            order_id: "排序",
            url: "Url",
        },
        field: {
            pid: "PID",
            order_id: "排序",
            name: "名稱",
            url: "Url",
            status: "狀態",
            icon: "圖標",
        },
    },
    login: {
        validate: {
            required: {
                username: "請輸入用戶名",
                email: "請輸入邮箱",
                password: "輸輸入密碼",
                captcha: "請輸入驗證碼",
            }
        }
    },
    annualLeavePlan:{
        name: {
            detailTitle: "AnnualLeavePlan Detail",
            editTitle: "Edit AnnualLeavePlan",
            search: "名稱"
        },
        table: {
            id_ann_lea_pla: "ID",
            plan_name: "計劃名稱",
            annual_leave_days: "年假",
            maternity_leave_days: "產假",
            paternity_leave_days: "侍產假",
            annual_leave_days_year_imcrement: "年假年遞增",
            max_annual_leave_days: "年假上限",
        },
        field: {
            id_ann_lea_pla: "ID",
            plan_name: "計劃名稱",
            annual_leave_days: "年假",
            maternity_leave_days: "產假",
            paternity_leave_days: "侍產假",
            annual_leave_days_year_imcrement: "年假年遞增",
            max_annual_leave_days: "年假上限",
        },
        validate: {
            required: {
                id_ann_lea_pla: "請填寫ID",
                plan_name: "請填寫計劃名稱",
                annual_leave_days: "請填寫年假",
                maternity_leave_days: "請填寫產假",
                paternity_leave_days: "請填寫侍產假",
                annual_leave_days_year_imcrement: "請填寫年假年遞增",
                max_annual_leave_days: "請填寫年假上限",
            }
        },
    },
    attendance:{
        name: {
            detailTitle: "Attendance Detail",
            editTitle: "Edit Attendance",
            search: "名稱",
            dateRange: "日期範圍",
            department: "部門",
            select_all: "全部",
            late: "是否遲到",
            earlyLeave: "是否早退"
        },
        table: {
            id_att: "ID",
            id_emp: "員工",
            check_in: "上班時間",
            check_out: "下班時間",
            late: "遲到",
            early_leave: "早退",
        },
        field: {
            id_att: "ID",
            id_emp: "員工",
            check_in: "上班時間",
            check_out: "下班時間",
            late: "遲到",
            early_leave: "早退",
        },
        validate: {
            required: {
                id_att: "請填寫ID",
                id_emp: "請填寫員工",
                check_in: "請填寫上班時間",
                check_out: "請填寫下班時間",
                late: "請填寫遲到",
                early_leave: "請填寫早退",
            }
        },
    },
    attendanceLogs:{
        name: {
            detailTitle: "AttendanceLogs Detail",
            editTitle: "Edit AttendanceLogs"
        },
        table: {
            id_att_log: "ID",
            id_emp: "員工",
            check_time: "拍卡時間",
            verify_mode: "驗證方式",
        },
        field: {
            id_att_log: "ID",
            id_emp: "員工",
            check_time: "拍卡時間",
            verify_mode: "驗證方式",
        },
        validate: {
            required: {
                id_att_log: "請填寫ID",
                id_emp: "請填寫員工",
                check_time: "請填寫拍卡時間",
                verify_mode: "請填寫驗證方式",
            }
        },
    },
    authority:{
        name: {
            detailTitle: "Authority Detail",
            editTitle: "Edit Authority"
        },
        table: {
            id_aut: "ID",
            a_id_aut: "父權限",
            name: "權限",
            resource_name: "權限標識",
            type: "權限類型",
        },
        field: {
            id_aut: "ID",
            a_id_aut: "父權限",
            name: "權限",
            resource_name: "權限標識",
            type: "權限類型",
        },
        validate: {
            required: {
                id_aut: "請填寫ID",
                a_id_aut: "請填寫父權限",
                name: "請填寫權限",
                resource_name: "請填寫權限標識",
                type: "請填寫權限類型",
            }
        },
    },
    department:{
        name: {
            detailTitle: "Department Detail",
            editTitle: "Edit Department",
            search: "名稱"
        },
        table: {
            id_dep: "ID",
            name: "部門",
            approver_id: "第一審批人",
            approver2_id: "第二審批人",
        },
        field: {
            id_dep: "ID",
            name: "部門",
            approver_id: "第一審批人",
            approver2_id: "第二審批人",
        },
        validate: {
            required: {
                id_dep: "請填寫ID",
                name: "請填寫部門",
                approver_id: "請填寫第一審批人",
                approver2_id: "請填寫第二審批人",
            }
        },
    },
    employee:{
        name: {
            detailTitle: "Employee Detail",
            editTitle: "Edit Employee",
            quit: "離職",
            revokeQuit: "撤銷離職",
            reinstated: "復職",
            search: "名稱",
            are_you_sure_quit: "是否確定離職該員工",
            are_you_sure_revoke_quit: "是否確定撤銷離職該員工",
            are_you_sure_reinstated: "是否確定複職該員工",
            status: "狀態",
            status_all: "全部",
            status_be_on_the_job: "在職",
            status_quit: "離職",
            gccsSetUser: "寫入考勤機",
            positionList: "曆史職位記錄",
            reset_password:"重置密碼"
        },
        table: {
            id_emp: "ID",
            id_rol: "職位",
            id_ann_lea_pla: "年假計劃",
            account: "賬號",
            password: "密碼",
            salt: "鹽",
            last_login_time: "上次登錄時間",
            register_time: "創建時間",
            login_failure_counter: "登錄錯誤計數",
            avatar_url: "頭像",
            no: "員工編號",
            name_tw: "員工姓名（中文）",
            name_en: "員工姓名（英）",
            id_card: "身份證",
            mobile_number: "手機號",
            phone_number: "電話",
            email: "郵箱",
            address: "地址",
            gender: "性别",
            birthday: "生日",
            remark: "備註",
            status: "狀態",
            duty_plan_type: "上班類型",
            emergency_contact: "緊急聯繫人",
            bank_account: "銀行賬號",
            nick_name: "暱稱",
            marital_status: "婚姻狀況",
            last_password_update_time: "上次密碼更新時間",
            enroll_id: "考勤機ID",
        },
        field: {
            id_emp: "ID",
            id_rol: "職位",
            id_ann_lea_pla: "年假計劃",
            account: "賬號",
            password: "密碼",
            salt: "鹽",
            last_login_time: "上次登錄時間",
            register_time: "創建時間",
            login_failure_counter: "登錄錯誤計數",
            avatar_url: "頭像",
            no: "員工編號",
            name_tw: "員工姓名（中文）",
            name_en: "員工姓名（英）",
            id_card: "身份證",
            mobile_number: "手機號",
            phone_number: "電話",
            email: "郵箱",
            address: "地址",
            gender: "性别",
            birthday: "生日",
            remark: "備註",
            status: "狀態",
            duty_plan_type: "上班類型",
            entry_date: "入職日期",
            emergency_contact: "緊急聯繫人",
            bank_account: "銀行賬號",
            nick_name: "暱稱",
            marital_status: "婚姻狀況",
            position:"職位",
            start_time:"開始時間",
            last_password_update_time: "上次密碼更新時間",
            enroll_id: "考勤機ID",
        },
        validate: {
            required: {
                id_emp: "請填寫ID",
                id_rol: "請填寫角色",
                id_ann_lea_pla: "請填寫年假計劃",
                account: "請填寫賬號",
                password: "長度至少為8，至少含有一個字母和一個數字和一個特殊字符",
                salt: "請填寫鹽",
                last_login_time: "請填寫上次登錄時間",
                register_time: "請填寫創建時間",
                login_failure_counter: "請填寫登錄錯誤計數",
                avatar_url: "請填寫頭像",
                entry_date: "請填寫入職日期",
                no: "請填寫員工編號",
                name_tw: "請填寫員工姓名（中文）",
                name_en: "請填寫員工姓名（英）",
                id_card: "請填寫身份證",
                mobile_number: "請填寫手機號",
                phone_number: "請填寫電話",
                email: "請填寫郵箱",
                address: "請填寫地址",
                gender: "請填寫性别",
                birthday: "請填寫生日",
                remark: "請填寫備註",
                status: "請填寫狀態",
                duty_plan_type: "請填寫上班類型",
                emergency_contact: "請填寫緊急聯繫人",
                bank_account: "請填寫銀行賬號",
                nick_name: "請填寫暱稱",
                marital_status: "請填寫婚姻狀況",
                last_password_update_time: "請填寫上次密碼更新時間",
                enroll_id: "請填寫考勤機ID",
            }
        },
    },
    employeeEntryRecord:{
        name: {
            detailTitle: "EmployeeEntryRecord Detail",
            editTitle: "Edit EmployeeEntryRecord",
            search: "名稱",
            new_join_report: "入職報告",
            term_report: "離職報告",
        },
        table: {
            id_emp_ent_rec: "ID",
            id_emp: "員工",
            entry_date: "員工入職時間",
            term_date: "員工離職時間",
            term_remark: "員工離職原因",
        },
        field: {
            id_emp_ent_rec: "ID",
            id_emp: "員工",
            entry_date: "員工入職時間",
            term_date: "員工離職時間",
            term_remark: "員工離職原因",
        },
        validate: {
            required: {
                id_emp_ent_rec: "請填寫ID",
                id_emp: "請填寫員工",
                entry_date: "請填寫員工入職時間",
                term_date: "請填寫員工離職時間",
                term_remark: "請填寫員工離職原因",
            }
        },
    },
    holiday:{
        name: {
            detailTitle: "Holiday Detail",
            editTitle: "Edit Holiday",
            search: "名稱"
        },
        table: {
            id_hol: "ID",
            holiday_name: "假期名稱",
            start_date: "開始時間",
            end_date: "結束時間",
            remark: "備註",
        },
        field: {
            id_hol: "ID",
            holiday_name: "假期名稱",
            start_date: "開始時間",
            end_date: "結束時間",
            remark: "備註",
        },
        validate: {
            required: {
                id_hol: "請填寫ID",
                holiday_name: "請填寫假期名稱",
                start_date: "請填寫開始時間",
                end_date: "請填寫結束時間",
                remark: "請填寫備註",
            }
        },
    },
    leave:{
        name: {
            detailTitle: "Leave Detail",
            editTitle: "Edit Leave",
            approve: "審批",
            search: "名稱",
            are_you_sure_send: "是否確認要發送該請假申請",
            not_enough_annual_leave_days: "年假不足!",
            not_enough_maternity_leave_days: "產假不足!",
            not_enough_paternity_leave_days: "侍產假不足!",
            leave_report: "請假報告",
        },
        table: {
            id_lea: "ID",
            id_emp: "員工",
            id_lea_typ: "請假類型",
            leave_type: "請假類型",
            start_time: "開始時間",
            end_time: "結束時間",
            duration: "時長（天）",
            reason: "原因",
            picture_url: "圖片路徑",
            approver_id: "第一審批人",
            approve_time: "第一審批時間",
            cc: "抄送",
            remark: "備註",
            status: "狀態",
            approver2_id: "第二審批人",
            approve2_time: "第二審批時間",
            reject_reason: "拒絕原因",
            submit_time: "申請日期",
            time_frame: "時段",
            time_unit: "時間單位(日)",
        },
        field: {
            id_lea: "ID",
            id_emp: "員工",
            id_lea_typ: "請假類型",
            leave_type: "請假類型",
            start_time: "開始時間",
            end_time: "結束時間",
            duration: "時長（天）",
            reason: "原因",
            picture_url: "圖片路徑",
            approver_id: "第一審批人",
            approve_time: "第一審批時間",
            cc: "抄送",
            remark: "備註",
            status: "狀態",
            approver2_id: "第二審批人",
            approve2_time: "第二審批時間",
            reject_reason: "拒絕原因",
            submit_time: "申請日期",
            time_frame: "時段",
            time_unit: "時間單位(日)",
        },
        validate: {
            required: {
                id_lea: "請填寫ID",
                id_emp: "請填寫員工",
                id_lea_typ: "請填寫請假類型",
                leave_type: "請填寫請假類型",
                start_time: "請填寫開始時間",
                end_time: "請填寫結束時間",
                duration: "請填寫時長",
                reason: "請填寫原因",
                picture_url: "請填寫圖片路徑",
                approver_id: "請填寫第一審批人",
                approve_time: "請填寫第一審批時間",
                cc: "請填寫抄送",
                remark: "請填寫備註",
                status: "請填寫狀態",
                approver2_id: "請填寫第二審批人",
                approve2_time: "請填寫第二審批時間",
                reject_reason: "請填寫拒絕原因",
                submit_time: "請填寫申請日期",
                time_frame: "請填寫時段",
                time_unit: "請填寫時間單位",
            }
        },
    },
    leaveType:{
        name: {
            detailTitle: "LeaveType Detail",
            editTitle: "Edit LeaveType"
        },
        table: {
            id_lea_typ: "ID",
            leave_type_name: "名稱",
            payroll_required: "需計算工資",
            leave_type_name_en: "名稱（英文）",
        },
        field: {
            id_lea_typ: "ID",
            leave_type_name: "名稱",
            payroll_required: "需計算工資",
            leave_type_name_en: "名稱（英文）",
        },
        validate: {
            required: {
                id_lea_typ: "請填寫ID",
                leave_type_name: "請填寫名稱",
                payroll_required: "請填寫需計算工資",
                leave_type_name_en: "請填寫名稱（英文）",
            }
        },
    },
    mpfSetting:{
        name: {
            detailTitle: "MpfSetting Detail",
            editTitle: "Edit MpfSetting"
        },
        table: {
            id_mpf_set: "ID",
            min: "開始計算金額（不含）",
            max: "結束計算金額（含）",
            percentage: "強積金百分比",
            fix_amount: "固定金額",
            caculate_method: "計算方式",
        },
        field: {
            id_mpf_set: "ID",
            min: "開始計算金額（不含）",
            max: "結束計算金額（含）",
            percentage: "強積金百分比",
            fix_amount: "固定金額",
            caculate_method: "計算方式",
        },
        validate: {
            required: {
                id_mpf_set: "請填寫ID",
                min: "請填寫開始計算金額（不含）",
                max: "請填寫結束計算金額（含）",
                percentage: "請填寫強積金百分比",
                fix_amount: "請填寫固定金額",
                caculate_method: "計算方式",
            }
        },
    },
    overtimeApplication:{
        name: {
            detailTitle: "OvertimeApplication Detail",
            editTitle: "Edit OvertimeApplication",
            search: "名稱",
            approve: "審批",
            historyApplication: "歷史申請",
            are_you_sure_pass: "確認通過加班申請"
        },
        table: {
            id_ove_app: "ID",
            id_emp: "員工",
            start_time: "開始時間",
            end_time: "結束時間",
            duration: "時長（小時）",
            approver_id: "審批人",
            approve_time: "審批時間",
            reason: "原因",
            status:"狀態",
            submit_time: "申請日期",
            time_frame: "時段",
            time_unit: "時間單位（小時）",
            status: "狀態",
            reject_reason: "拒絕原因",
            approver2_id: "第二審批人",
            approver2_time: "第二審批時間",
            time_remark: "時段",
        },
        field: {
            id_ove_app: "ID",
            id_emp: "員工",
            start_time: "開始時間",
            end_time: "結束時間",
            duration: "時長（小時）",
            approver_id: "審批人",
            approve_time: "審批時間",
            reason: "原因",
            submit_time: "申請日期",
            time_frame: "時段",
            time_unit: "時間單位（小時）",
            status: "狀態",
            reject_reason: "拒絕原因",
            approver2_id: "第二審批人",
            approver2_time: "第二審批時間",
            time_remark: "時段",
        },
        validate: {
            required: {
                id_ove_app: "請填寫ID",
                id_emp: "請填寫員工",
                start_time: "請填寫開始時間",
                end_time: "請填寫結束時間",
                duration: "請填寫時長",
                approver_id: "請填寫審批人",
                approve_time: "請填寫審批時間",
                reason: "請填寫原因",
                submit_time: "請填寫申請日期",
                time_frame: "請填寫時段",
                time_unit: "請填寫時間單位",
                status: "請填寫狀態",
                reject_reason: "請填寫拒絕原因",
                approver2_id: "請填寫第二審批人",
                approver2_time: "請填寫第二審批時間",
                time_remark: "請填寫時段",
            }
        },
    },
    remainingVacation:{
        name: {
            detailTitle: "RemainingVacation Detail",
            editTitle: "Edit RemainingVacation",
            search: "名稱",
            year: "年份",
            lessThan: "年假小於",
            moreThan: "年假大於",
            prediction: "預算年假"
        },
        table: {
            id_rem_vac: "ID",
            id_emp: "員工",
            annual_leave_days: "總年假",
            maternity_leave_days: "總產假",
            paternity_leave_days: "總侍產假",
            remaining_annual_leave_days: "剩餘年假",
            remaining_maternity_leave_days: "剩餘產假",
            remaining_paternity_leave_days: "剩餘侍產假",
            year: "所屬年份",
            remark: "備註",
            remaining_sick_leave_days: "剩餘病假",
            vacation_type:"假期類型",
            vacation:"應得假期（截至假期年末）",
            used_vacation:"已用假期（已獲批之未放假期）",
            remaining_vacation:"假期結餘（截至假期年末）",
            real_annual_leave_days: "當前實際剩餘年假",
            remaining_birthday_leave_days: "剩餘生日假",
            total_maternity:"總產假",
            total_paternity:"總侍產假",
            total_birthday:"總生日假",
        },
        field: {
            id_rem_vac: "ID",
            id_emp: "員工",
            annual_leave_days: "總年假",
            maternity_leave_days: "總產假",
            paternity_leave_days: "總侍產假",
            remaining_annual_leave_days: "剩餘年假",
            remaining_maternity_leave_days: "剩餘產假",
            remaining_paternity_leave_days: "剩餘侍產假",
            year: "所屬年份",
            remark: "備註",
            remaining_sick_leave_days: "剩餘病假",
            sick_leave_days: "總病假",
            real_annual_leave_days: "當前實際剩餘年假",
            remaining_birthday_leave_days: "剩餘生日假",
        },
        validate: {
            required: {
                id_rem_vac: "請填寫ID",
                id_emp: "請填寫員工",
                annual_leave_days: "請填寫總年假",
                maternity_leave_days: "請填寫總產假",
                paternity_leave_days: "請填寫總侍產假",
                remaining_annual_leave_days: "請填寫剩餘年假",
                remaining_maternity_leave_days: "請填寫剩餘產假",
                remaining_paternity_leave_days: "請填寫剩餘侍產假",
                year: "請填寫所屬年份",
                remark: "請填寫備註",
                remaining_sick_leave_days: "請填寫剩餘病假",
                real_annual_leave_days: "請填寫當前實際剩餘年假",
                remaining_birthday_leave_days: "請填寫剩餘生日假",
            }
        },
    },
    report:{
        name: {
            detailTitle: "Report Detail",
            editTitle: "Edit Report",
            month: "月份",
            month_placeholder: "選擇月份",
            generalSalary: "生成工資記錄",
            batchDelete: "批量刪除",
            totalSalary: "工資總計",
            totalMpf: "僱員強積金總計",
            totalRealSalary: "實發工資",
            search: "名稱",
            batchConfirm: "批量確認",
            are_you_sure_batch_delete: "確認要批量刪除嗎",
            are_you_sure_confirm: "是否需要確認該記錄",
            are_you_sure_batch_confirm: "是否需要批量確認所有記錄",
            downSlip: "下載糧單",
            down_payroll_report: "下載薪金報表",
            sendSlip:"發送糧單",
            down_yearly_payroll_report:"下載年報"
        },
        table: {
            id_rep: "ID",
            id_emp: "員工",
            month: "月份",
            base_salary: "基礎工資",
            allowance: "津貼",
            car_subsidy: "車補",
            house_subsidy: "房補",
            other_subsidy: "其他補貼",
            commission: "佣金",
            overtime_times: "加班次數",
            overtime_pay: "加班工資",
            overtime: "加班時間",
            bonus: "花紅",
            compensatory_leave_time: "調休時間",
            total_use_annual_leave: "本年已用年假",
            total_annual_leave: "本年總年假",
            use_annual_leave: "本月總使用年假",
            remark: "備註",
            leave_time: "本月請假時間",
            absence_days: "缺勤天數",
            create_time: "創建時間",
            mpf: "僱員強積金",
            other_deductions: "其他扣除",
            total_salary: "實發金額",
            total_wages: "總工資",
            attendance_days: "出勤天數",
            rest_days: "休息天數",
            work_hours: "工作時長",
            late_time: "遲到時間",
            late_times: "遲到次數",
            serious_late_times: "嚴重遲到次數",
            serious_late_time: "嚴重遲到時長",
            absenteeism_times: "曠工次數",
            early_leave_time: "早退時間",
            early_leave_times: "早退次數",
            no_check_in_times: "上班缺卡次數",
            no_check_out_times: "下班缺卡次數",
            status: "狀態",
            need_attendance_days: "應出勤天數",
            need_mpf_days: "應計算MPF天數",
            mpf_start_date: "MPF開始計算日期",
            join_date: "入職日期",
            pay_date: "支付日期",
            employer_mpf: "僱主強積金",
        },
        field: {
            id_rep: "ID",
            id_emp: "員工",
            month: "月份",
            base_salary: "基礎工資",
            allowance: "津貼",
            car_subsidy: "車補",
            house_subsidy: "房補",
            other_subsidy: "其他補貼",
            commission: "佣金",
            overtime_times: "加班次數",
            overtime_pay: "加班工資",
            overtime: "加班時間",
            bonus: "花紅",
            compensatory_leave_time: "調休時間",
            total_use_annual_leave: "本年已用年假",
            total_annual_leave: "本年總年假",
            use_annual_leave: "本月總使用年假",
            remark: "備註",
            leave_time: "本月請假時間",
            absence_days: "缺勤天數",
            create_time: "創建時間",
            mpf: "僱員強積金",
            other_deductions: "其他扣除",
            total_salary: "實發金額",
            total_wages: "總工資",
            attendance_days: "出勤天數",
            rest_days: "休息天數",
            work_hours: "工作時長",
            late_time: "遲到時間",
            late_times: "遲到次數",
            serious_late_times: "嚴重遲到次數",
            serious_late_time: "嚴重遲到時長",
            absenteeism_times: "曠工次數",
            early_leave_time: "早退時間",
            early_leave_times: "早退次數",
            no_check_in_times: "上班缺卡次數",
            no_check_out_times: "下班缺卡次數",
            status: "狀態",
            need_attendance_days: "應出勤天數",
            mpf_start_date: "MPF開始計算日期",
            employer_mpf: "僱主強積金",
        },
        validate: {
            required: {
                id_rep: "請填寫ID",
                id_emp: "請填寫員工",
                month: "請選擇月份",
                base_salary: "請填寫基礎工資",
                allowance: "請填寫津貼",
                car_subsidy: "請填寫車補",
                house_subsidy: "請填寫房補",
                other_subsidy: "請填寫其他補貼",
                commission: "請填寫佣金",
                overtime_times: "請填寫加班次數",
                overtime_pay: "請填寫加班工資",
                overtime: "請填寫加班時間",
                bonus: "請填寫花紅",
                compensatory_leave_time: "請填寫調休時間",
                total_use_annual_leave: "請填寫本年已用年假",
                total_annual_leave: "請填寫本年總年假",
                use_annual_leave: "請填寫本月總使用年假",
                remark: "請填寫備註",
                leave_time: "請填寫本月請假時間",
                absence_days: "請填寫缺勤天數",
                create_time: "請填寫創建時間",
                mpf: "請填寫強積金",
                other_deductions: "請填寫其他扣除",
                total_salary: "請填寫實發金額",
                total_wages: "請填寫總工資",
                attendance_days: "請填寫出勤天數",
                rest_days: "請填寫休息天數",
                work_hours: "請填寫工作時長",
                late_time: "請填寫遲到時間",
                late_times: "請填寫遲到次數",
                serious_late_times: "請填寫嚴重遲到次數",
                serious_late_time: "請填寫嚴重遲到時長",
                absenteeism_times: "請填寫曠工次數",
                early_leave_time: "請填寫早退時間",
                early_leave_times: "請填寫早退次數",
                no_check_in_times: "請填寫上班缺卡次數",
                no_check_out_times: "請填寫下班缺卡次數",
                status: "請填寫狀態",
                status: "請填寫狀態",
                need_attendance_days: "請填寫應出勤天數",
                employer_mpf: "請填寫僱主強積金",
            }
        },
    },
    role:{
        name: {
            detailTitle: "Role Detail",
            editTitle: "Edit Role"
        },
        table: {
            id_rol: "ID",
            id_dep: "所屬部門",
            name: "職位",
            remark: "備註",
                        approver_id: "第一審批人",
            approver2_id: "第二審批人",
        },
        field: {
            id_rol: "ID",
            id_dep: "所屬部門",
            name: "職位",
            remark: "備註",
            authorityList: "權限",
                        approver_id: "第一審批人",
            approver2_id: "第二審批人",
        },
        validate: {
            required: {
                id_rol: "請填寫ID",
                id_dep: "請填寫所屬部門",
                name: "請填寫職位",
                remark: "請填寫備註",
                authorityList: "請選擇權限",
                                approver_id: "請填寫第一審批人",
                approver2_id: "請填寫第二審批人",
            }
        },
    },
    salaryInformation:{
        name: {
            detailTitle: "SalaryInformation Detail",
            editTitle: "Edit SalaryInformation",
            search: "名稱",
        },
        table: {
            id_sal_inf: "ID",
            id_emp: "員工",
            base_salary: "基礎工資",
            allowance: "津貼",
            car_subsidy: "車補",
            house_subsidy: "房補",
            saturday_overtime_multiples: "週六加班倍數",
            sunday_overtime_multiples: "週日加班倍數",
            holiday_overtime_multiples: "節假日加班倍數",
            normal_overtime_multiples: "平日加班倍數",
            remark: "備註",
            create_time: "創建時間",
            expire_time: "失效時間",
            status: "狀態",
            commission: "佣金",
            bonus: "花紅",
        },
        field: {
            id_sal_inf: "ID",
            id_emp: "員工",
            base_salary: "基礎工資",
            allowance: "津貼",
            car_subsidy: "車補",
            house_subsidy: "房補",
            saturday_overtime_multiples: "週六加班倍數",
            sunday_overtime_multiples: "週日加班倍數",
            holiday_overtime_multiples: "節假日加班倍數",
            normal_overtime_multiples: "平日加班倍數",
            remark: "備註",
            create_time: "創建時間",
            expire_time: "失效時間",
            status: "狀態",
            commission: "佣金",
            bonus: "花紅",
        },
        validate: {
            required: {
                id_sal_inf: "請填寫ID",
                id_emp: "請填寫員工",
                base_salary: "請填寫基礎工資",
                allowance: "請填寫津貼",
                car_subsidy: "請填寫車補",
                house_subsidy: "請填寫房補",
                saturday_overtime_multiples: "請填寫週六加班倍數",
                sunday_overtime_multiples: "請填寫週日加班倍數",
                holiday_overtime_multiples: "請填寫節假日加班倍數",
                normal_overtime_multiples: "請填寫平日加班倍數",
                remark: "請填寫備註",
                create_time: "請填寫創建時間",
                expire_time: "請填寫失效時間",
                commission: "請填寫佣金",
                bonus: "請填寫花紅",
            }
        },
    },
    generalSalary:{
        name: {
            title: "生成工資",
            month: "月份",
            month_placeholder: "選擇月份",
            generalSalary: "生成"
        },
        table: {
            id_rep: "ID",
            id_emp: "員工",
            month: "月份",
            base_salary: "基礎工資",
            allowance: "津貼",
            car_subsidy: "車補",
            house_subsidy: "房補",
            other_subsidy: "其他補貼",
            commission: "佣金",
            overtime_times: "加班次數",
            overtime_pay: "加班工資",
            overtime: "加班時間（分鐘）",
            bonus: "獎金",
            compensatory_leave_time: "調休時間",
            total_use_annual_leave: "本年已用年假",
            total_annual_leave: "本年總年假",
            use_annual_leave: "本月總使用年假",
            remark: "備註",
            leave_time: "本月請假時間",
            absence_days: "缺勤天數",
            create_time: "創建時間",
            mpf: "強積金",
            other_deductions: "其他扣除",
            total_salary: "實發金額",
            attendance_days: "出勤天數",
            rest_days: "休息天數",
            work_hours: "工作時長",
            late_time: "遲到時間",
            late_times: "遲到次數",
            serious_late_times: "嚴重遲到次數",
            serious_late_time: "嚴重遲到時長",
            absenteeism_times: "曠工次數",
            early_leave_time: "早退時間",
            early_leave_times: "早退次數",
            no_check_in_times: "上班缺卡次數",
            no_check_out_times: "下班缺卡次數",
        },
        field: {
            id_rep: "ID",
            id_emp: "員工",
            month: "月份",
            base_salary: "基礎工資",
            allowance: "津貼",
            car_subsidy: "車補",
            house_subsidy: "房補",
            other_subsidy: "其他補貼",
            commission: "佣金",
            overtime_times: "加班次數",
            overtime_pay: "加班工資",
            overtime: "加班時間（分鐘）",
            bonus: "獎金",
            compensatory_leave_time: "調休時間",
            total_use_annual_leave: "本年已用年假",
            total_annual_leave: "本年總年假",
            use_annual_leave: "本月總使用年假",
            remark: "備註",
            leave_time: "本月請假時間",
            absence_days: "缺勤天數",
            create_time: "創建時間",
            mpf: "強積金",
            other_deductions: "其他扣除",
            total_salary: "實發金額",
            attendance_days: "出勤天數",
            rest_days: "休息天數",
            work_hours: "工作時長",
            late_time: "遲到時間",
            late_times: "遲到次數",
            serious_late_times: "嚴重遲到次數",
            serious_late_time: "嚴重遲到時長",
            absenteeism_times: "曠工次數",
            early_leave_time: "早退時間",
            early_leave_times: "早退次數",
            no_check_in_times: "上班缺卡次數",
            no_check_out_times: "下班缺卡次數",
        },
        validate: {
            required: {
                id_rep: "請填寫ID",
                id_emp: "請填寫員工",
                month: "請填寫月份",
                base_salary: "請填寫基礎工資",
                allowance: "請填寫津貼",
                car_subsidy: "請填寫車補",
                house_subsidy: "請填寫房補",
                other_subsidy: "請填寫其他補貼",
                commission: "請填寫佣金",
                overtime_times: "請填寫加班次數",
                overtime_pay: "請填寫加班工資",
                overtime: "請填寫加班時間",
                bonus: "請填寫獎金",
                compensatory_leave_time: "請填寫調休時間",
                total_use_annual_leave: "請填寫本年已用年假",
                total_annual_leave: "請填寫本年總年假",
                use_annual_leave: "請填寫本月總使用年假",
                remark: "請填寫備註",
                leave_time: "請填寫本月請假時間",
                absence_days: "請填寫缺勤天數",
                create_time: "請填寫創建時間",
                mpf: "請填寫強積金",
                other_deductions: "請填寫其他扣除",
                total_salary: "請填寫實發金額",
                attendance_days: "請填寫出勤天數",
                rest_days: "請填寫休息天數",
                work_hours: "請填寫工作時長",
                late_time: "請填寫遲到時間",
                late_times: "請填寫遲到次數",
                serious_late_times: "請填寫嚴重遲到次數",
                serious_late_time: "請填寫嚴重遲到時長",
                absenteeism_times: "請填寫曠工次數",
                early_leave_time: "請填寫早退時間",
                early_leave_times: "請填寫早退次數",
                no_check_in_times: "請填寫上班缺卡次數",
                no_check_out_times: "請填寫下班缺卡次數",
            }
        },
    },
    leaveApprove:{
        name: {
            title: "請假審批",
            employeeInfo: "員工信息",
            remainingVacation: "剩餘年假",
            historyLeave: "歷史請假記錄",
            pass: "通過",
            reject: "拒絕",
            are_you_sure_pass: "確認通過該請假申請",
            reject_title: "拒絕請假"
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    overtimeApplicationApprove:{
        name: {
            title: "加班申請審批",
            overtimeApplication: "加班申請信息",
            historyApplication: "曆史申請",
            reject_title: "拒絕加班申請"
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    gccsSetUser:{
        name: {
            title: "寫入考勤機",
        },
        table: {
            
        },
        field: {
            device: "設備",
            user_privilege: "考勤機權限",
        },
        validate: {
            required: {
                device: "請選擇設備",
                user_privilege: "請選擇權限",
            }
        },
    },
    downloadLeaveReport:{
        name: {
            title: "請假報告",
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    newJoinReport:{
        name: {
            title: "入職報告",
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    termReport:{
        name: {
            title: "離職報告",
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    reportConfirm:{
        name: {
            title: "確認",
        },
        table: {
            
        },
        field: {
            pay_date:"支付日期"
        },
        validate: {
            required: {
                pay_date: "請輸入支付日期",
            }
        },
    },
    settings:{
        name: {
            detailTitle: "Settings Detail",
            editTitle: "Edit Settings"
        },
        table: {
            id_set: "ID",
            name: "名稱",
            content: "內容",
        },
        field: {
            id_set: "ID",
            name: "名稱",
            content: "內容",
        },
        validate: {
            required: {
                id_set: "請填寫ID",
                name: "請填寫名稱",
                content: "請填寫內容",
            }
        },
    },
    config:{
        name: {
            email_setting: "郵箱設定",
        },
        table: {
        },
        field: {
            host: "域名",
            username: "用戶名",
            password: "密碼",
            sender: "發送者郵箱",
        },
        validate: {
            required: {
                host: "請輸入域名",
                username: "請輸入用戶名",
                password: "請輸入密碼",
                sender: "請輸入發送者郵箱",
            }
        },
    },
    roleLogs:{
        name: {
            detailTitle: "RoleLogs Detail",
            editTitle: "Edit RoleLogs"
        },
        table: {
            id_rol_log: "ID",
            id_emp: "員工",
            create_time: "創建時間",
            position_name: "職位名稱",
        },
        field: {
            id_rol_log: "ID",
            id_emp: "員工",
            create_time: "創建時間",
            position_name: "職位名稱",
        },
        validate: {
            required: {
                id_rol_log: "請填寫ID",
                id_emp: "請填寫員工",
                create_time: "請填寫創建時間",
                position_name: "請填寫職位名稱",
            }
        },
    },
    prediction:{
      name: {
          title: "預算年假",
          date: "日期"
      },
      table: {
          
      },
      field: {
          
      },
      validate: {
          required: {
              date: "請輸入日期",
          }
      },
    },
}

export default zhTW