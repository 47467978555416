<template>
  <Modal
    id="EmployeeEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="employeeForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                    <FormItem :label="$t('employee.field.id_rol')" name="idRol">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idRol"
                              :placeholder="$t('employee.validate.required.id_rol')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['roleList']" :value="item['idRol']">{{ item['departmentName'] + '->' + item['name'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.id_ann_lea_pla')" name="idAnnLeaPla">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idAnnLeaPla"
                              :placeholder="$t('employee.validate.required.id_ann_lea_pla')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['annualLeavePlanList']" :value="item['idAnnLeaPla']">{{ item['planName'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'add'">
                    <FormItem :label="$t('employee.field.account')" name="account">
                      <Input :placeholder="$t('employee.validate.required.account')"
                             v-model:value="form.account">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'add'">
                    <FormItem :label="$t('employee.field.password')" name="password">
                      <InputPassword :placeholder="$t('employee.validate.required.password')"
                                     v-model:value="form.password">
                      </InputPassword>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.avatar_url')" ref="avatarUrl" name="avatarUrl">
                      <UploadImage :url="form.avatarUrl"
                                   @new_image_url="(value) => {
                                      this.$refs.avatarUrl.clearValidate()
                                      this.form.avatarUrl = value
                                   }"
                                   ref="upload_avatarUrl"
                      />
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'add'">
                    <FormItem :label="$t('employee.field.entry_date')" name="entryDate">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.entryDate" :inputReadOnly="true" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.no')" name="no">
                      <Input :placeholder="$t('employee.validate.required.no')"
                             v-model:value="form.no">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.name_tw')" name="nameTw">
                      <Input :placeholder="$t('employee.validate.required.name_tw')"
                             v-model:value="form.nameTw">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.name_en')" name="nameEn">
                      <Input :placeholder="$t('employee.validate.required.name_en')"
                             v-model:value="form.nameEn">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.id_card')" name="idCard">
                      <Input :placeholder="$t('employee.validate.required.id_card')"
                             v-model:value="form.idCard">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.mobile_number')" name="mobileNumber">
                      <Input :placeholder="$t('employee.validate.required.mobile_number')"
                             v-model:value="form.mobileNumber">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.phone_number')" name="phoneNumber">
                      <Input :placeholder="$t('employee.validate.required.phone_number')"
                             v-model:value="form.phoneNumber">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.email')" name="email">
                      <Input :placeholder="$t('employee.validate.required.email')"
                             v-model:value="form.email">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.address')" name="address">
                      <Textarea
                              v-model:value="form.address"
                              :placeholder="$t('employee.validate.required.address')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.gender')" name="gender">
                      <Select
                              v-model:value="form.gender"
                              :placeholder="$t('employee.validate.required.gender')"
                              :options="selectList.genderList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.birthday')" name="birthday">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.birthday" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.remark')" name="remark">
                      <Textarea
                              v-model:value="form.remark"
                              :placeholder="$t('employee.validate.required.remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.duty_plan_type')" name="dutyPlanType">
                      <Select
                              v-model:value="form.dutyPlanType"
                              :placeholder="$t('employee.validate.required.duty_plan_type')"
                              :options="selectList.dutyPlanTypeList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.emergency_contact')" name="emergencyContact">
                      <Input :placeholder="$t('employee.validate.required.emergency_contact')"
                             v-model:value="form.emergencyContact">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.bank_account')" name="bankAccount">
                      <Input :placeholder="$t('employee.validate.required.bank_account')"
                             v-model:value="form.bankAccount">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.nick_name')" name="nickName">
                      <Input :placeholder="$t('employee.validate.required.nick_name')"
                             v-model:value="form.nickName">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.marital_status')" name="maritalStatus">
                      <Select
                              v-model:value="form.maritalStatus"
                              :placeholder="$t('employee.validate.required.marital_status')"
                              :options="selectList.maritalStatusList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                    </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('employee.field.enroll_id')" name="enrollId">
                      <InputNumber :placeholder="$t('employee.validate.required.enroll_id')" :stringMode="true"
                             v-model:value="form.enrollId">
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="24">
                  <Card title="每週考勤時間（不用上班則留空）">
                    <CardGrid style="width: 50%; text-align: center">
                      <FormItem label="週一" name="mondayAttendanceTime">
                        <TimeRangePicker format="HH:mm" valueFormat="HH:mm" v-model:value="attendanceJson.mondayAttendanceTime"/>
                      </FormItem>
                    </CardGrid>
                    <CardGrid style="width: 50%; text-align: center">
                      <FormItem label="週二" name="tuesdayAttendanceTime">
                        <TimeRangePicker format="HH:mm" valueFormat="HH:mm" v-model:value="attendanceJson.tuesdayAttendanceTime"/>
                      </FormItem>
                    </CardGrid>
                    <CardGrid style="width: 50%; text-align: center">
                      <FormItem label="週三" name="wednesdayAttendanceTime">
                        <TimeRangePicker format="HH:mm" valueFormat="HH:mm" v-model:value="attendanceJson.wednesdayAttendanceTime"/>
                      </FormItem>
                    </CardGrid>
                    <CardGrid style="width: 50%; text-align: center">
                      <FormItem label="週四" name="thursdayAttendanceTime">
                        <TimeRangePicker format="HH:mm" valueFormat="HH:mm" v-model:value="attendanceJson.thursdayAttendanceTime"/>
                      </FormItem>
                    </CardGrid>
                    <CardGrid style="width: 50%; text-align: center">
                      <FormItem label="週五" name="firdayAttendanceTime">
                        <TimeRangePicker format="HH:mm" valueFormat="HH:mm" v-model:value="attendanceJson.firdayAttendanceTime"/>
                      </FormItem>
                    </CardGrid>
                    <CardGrid style="width: 50%; text-align: center">
                      <FormItem label="週六" name="saturdayAttendanceTime">
                        <TimeRangePicker format="HH:mm" valueFormat="HH:mm" v-model:value="attendanceJson.saturdayAttendanceTime"/>
                      </FormItem>
                    </CardGrid>
                    <CardGrid style="width: 50%; text-align: center">
                      <FormItem label="週日" name="sundayAttendanceTime">
                        <TimeRangePicker format="HH:mm" valueFormat="HH:mm" v-model:value="attendanceJson.sundayAttendanceTime"/>
                      </FormItem>
                    </CardGrid>

                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Card,
  CardGrid,
  RangePicker,
  TimeRangePicker,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { EmployeeAdd } from "@/api/EmployeeAdd";
import { EmployeeEdit } from "@/api/EmployeeEdit";
import { RoleListAll } from "@/api/RoleListAll"
import { AnnualLeavePlanListAll } from "@/api/AnnualLeavePlanListAll"
import UploadImage from "@/components/Common/UploadImage"
import moment from "moment";


export default {
  name: "EmployeeEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    UploadImage,
    Descriptions,
    DescriptionsItem,
    Card,
    CardGrid,
    RangePicker,
    TimeRangePicker,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idEmp : "",
        idRol : "",
        idAnnLeaPla : "",
        account : "",
        password : "",
        avatarUrl : "",
        nameTw : "",
        nameEn : "",
        idCard : "",
        mobileNumber : "",
        phoneNumber : "",
        email : "",
        address : "",
        gender : "",
        birthday : "",
        remark : "",
        dutyPlanType : "",
        emergencyContact : "",
        bankAccount : "",
        nickName : "",
        maritalStatus : "",
        enrollId : "",
      },
      attendanceJson:{
        mondayAttendanceTime:[],
        tuesdayAttendanceTime:[],
        wednesdayAttendanceTime:[],
        thursdayAttendanceTime:[],
        firdayAttendanceTime:[],
        saturdayAttendanceTime:[],
        sundayAttendanceTime:[],
      },
      fileList: {
      },
      selectList: {
        roleList: [],
        annualLeavePlanList: [],
        genderList:[{value: 0,label: '女'},{value: 1,label: '男'}],
        dutyPlanTypeList: [{value: 1,label: '週一到週五'},{value: 2,label: '週一到週六早上'},{value: 3,label: '週一到週六'},{value: 4,label: '長短週'}],
        maritalStatusList: [{value: 0,label: '未婚'},{value: 1,label: '已婚'}],
      },
      validateRules: {
        idEmp: [
          {
            required: true,
            message: this.$t("employee.validate.required.id_emp"),
            trigger: "change",
          },
        ],
        idRol: [
          {
            required: true,
            message: this.$t("employee.validate.required.id_rol"),
            trigger: "change",
            type: "number"
          },
        ],
        idAnnLeaPla: [
          {
            required: true,
            message: this.$t("employee.validate.required.id_ann_lea_pla"),
            trigger: "change",
            type: "number"
          },
        ],
        account: [
          {
            required: true,
            message: this.$t("employee.validate.required.account"),
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("employee.validate.required.password"),
            pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            trigger: "change",
          },
        ],
        salt: [
          {
            required: true,
            message: this.$t("employee.validate.required.salt"),
            trigger: "change",
          },
        ],
        lastLoginTime: [
          {
            required: true,
            message: this.$t("employee.validate.required.last_login_time"),
            trigger: "change",
          },
        ],
        registerTime: [
          {
            required: true,
            message: this.$t("employee.validate.required.register_time"),
            trigger: "change",
          },
        ],
        loginFailureCounter: [
          {
            required: true,
            message: this.$t("employee.validate.required.login_failure_counter"),
            trigger: "change",
          },
        ],
        avatarUrl: [
          {
            required: false,
            message: this.$t("employee.validate.required.avatar_url"),
            trigger: "change",
          },
        ],
        entryDate: [
          {
            required: true,
            message: this.$t("employee.validate.required.entry_date"),
            trigger: "change",
          },
        ],
        no: [
          {
            required: true,
            message: this.$t("employee.validate.required.no"),
            trigger: "change",
          },
        ],
        nameTw: [
          {
            required: true,
            message: this.$t("employee.validate.required.name_tw"),
            trigger: "change",
            max: 20,
          },
        ],
        nameEn: [
          {
            required: true,
            message: this.$t("employee.validate.required.name_en"),
            trigger: "change",
          },
        ],
        idCard: [
          {
            required: true,
            message: this.$t("employee.validate.required.id_card"),
            trigger: "change",
          },
        ],
        mobileNumber: [
          {
            required: true,
            message: this.$t("employee.validate.required.mobile_number"),
            trigger: "change",
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t("employee.validate.required.phone_number"),
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("employee.validate.required.email"),
            trigger: "change",
            type: 'email',
          },
        ],
        address: [
          {
            required: true,
            message: this.$t("employee.validate.required.address"),
            trigger: "change",
          },
        ],
        gender: [
          {
            required: true,
            message: this.$t("employee.validate.required.gender"),
            trigger: "change",
            type: "number"
          },
        ],
        birthday: [
          {
            required: true,
            message: this.$t("employee.validate.required.birthday"),
            trigger: "change",
          },
        ],
        remark: [
          {
            required: false,
            message: this.$t("employee.validate.required.remark"),
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("employee.validate.required.status"),
            trigger: "change",
          },
        ],
        dutyPlanType: [
          {
            required: true,
            message: this.$t("employee.validate.required.duty_plan_type"),
            trigger: "change",
            type: "number",
          },
        ],
        emergencyContact: [
          {
            required: true,
            message: this.$t("employee.validate.required.emergency_contact"),
            trigger: "change",
          },
        ],
        bankAccount: [
          {
            required: true,
            message: this.$t("employee.validate.required.bank_account"),
            trigger: "change",
          },
        ],
        nickName: [
          {
            required: true,
            message: this.$t("employee.validate.required.nick_name"),
            trigger: "change",
          },
        ],
        maritalStatus: [
          {
            required: true,
            message: this.$t("employee.validate.required.marital_status"),
            trigger: "change",
            type: "number",
          },
        ],
        enrollId: [
          {
            required: true,
            message: this.$t("employee.validate.required.enroll_id"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getRoleList();
      this.getAnnualLeavePlanList();
      if (this.$refs.upload_avatarUrl) {
          this.$refs.upload_avatarUrl.change_url("")
      }
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      if (this.$refs.upload_avatarUrl) {
          this.$refs.upload_avatarUrl.change_url("")
      }
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      if(this.form.attendanceJson){
        this.attendanceJson = JSON.parse(this.form.attendanceJson)
      }
      this.getRoleList();
      this.getAnnualLeavePlanList();
        if (this.$refs.upload_avatarUrl) {
            this.$refs.upload_avatarUrl.change_url(this.form.avatarUrl)
        }
      this.visible = true;
    },
    submit() {
      const format = 'HH:mm'
      //考勤結束時間必須大於開始時間
      if(this.attendanceJson.mondayAttendanceTime && this.attendanceJson.mondayAttendanceTime.length > 0){
        const startTime= moment(this.attendanceJson.mondayAttendanceTime[0],format);
        const endTime = moment(this.attendanceJson.mondayAttendanceTime[1],format);
        if(startTime.isAfter(endTime)){
          message.error("週一考勤開始時間小於結束時間，考勤開始時間必須大於結束時間！")
          return;
        }
      }
      if(this.attendanceJson.tuesdayAttendanceTime && this.attendanceJson.tuesdayAttendanceTime.length > 0){
        const startTime= moment(this.attendanceJson.tuesdayAttendanceTime[0],format);
        const endTime = moment(this.attendanceJson.tuesdayAttendanceTime[1],format);
        if(startTime.isAfter(endTime)){
          message.error("週二考勤開始時間小於結束時間，考勤開始時間必須大於結束時間！")
          return;
        }
      }
      if(this.attendanceJson.wednesdayAttendanceTime && this.attendanceJson.wednesdayAttendanceTime.length > 0){
        const startTime= moment(this.attendanceJson.wednesdayAttendanceTime[0],format);
        const endTime = moment(this.attendanceJson.wednesdayAttendanceTime[1],format);
        if(startTime.isAfter(endTime)){
          message.error("週三考勤開始時間小於結束時間，考勤開始時間必須大於結束時間！")
          return;
        }
      }
      if(this.attendanceJson.thursdayAttendanceTime && this.attendanceJson.thursdayAttendanceTime.length > 0){
        const startTime= moment(this.attendanceJson.thursdayAttendanceTime[0],format);
        const endTime = moment(this.attendanceJson.thursdayAttendanceTime[1],format);
        if(startTime.isAfter(endTime)){
          message.error("週四考勤開始時間小於結束時間，考勤開始時間必須大於結束時間！")
          return;
        }
      }
      if(this.attendanceJson.firdayAttendanceTime && this.attendanceJson.firdayAttendanceTime.length > 0){
        const startTime= moment(this.attendanceJson.firdayAttendanceTime[0],format);
        const endTime = moment(this.attendanceJson.firdayAttendanceTime[1],format);
        if(startTime.isAfter(endTime)){
          message.error("週五考勤開始時間小於結束時間，考勤開始時間必須大於結束時間！")
          return;
        }
      }
      if(this.attendanceJson.saturdayAttendanceTime && this.attendanceJson.saturdayAttendanceTime.length > 0){
        const startTime= moment(this.attendanceJson.saturdayAttendanceTime[0],format);
        const endTime = moment(this.attendanceJson.saturdayAttendanceTime[1],format);
        if(startTime.isAfter(endTime)){
          message.error("週六考勤開始時間小於結束時間，考勤開始時間必須大於結束時間！")
          return;
        }
      }
      if(this.attendanceJson.sundayAttendanceTime && this.attendanceJson.sundayAttendanceTime.length > 0){
        const startTime= moment(this.attendanceJson.sundayAttendanceTime[0],format);
        const endTime = moment(this.attendanceJson.sundayAttendanceTime[1],format);
        if(startTime.isAfter(endTime)){
          message.error("週日考勤開始時間小於結束時間，考勤開始時間必須大於結束時間！")
          return;
        }
      }


      this.$refs["employeeForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new EmployeeAdd();
              break;
            case "edit":
              console.log("edit");
              api = new EmployeeEdit();
              break;
            default:
              return false;
          }
          this.form.attendanceJson = JSON.stringify(this.attendanceJson)
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getRoleList() {
      const api = new RoleListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.roleList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    getAnnualLeavePlanList() {
      const api = new AnnualLeavePlanListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.annualLeavePlanList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    // reset data
    _resetData() {
      this.form = {
        idEmp : "",
        idRol : "",
        idAnnLeaPla : "",
        account : "",
        password : "",
        avatarUrl : "",
        nameTw : "",
        nameEn : "",
        idCard : "",
        mobileNumber : "",
        phoneNumber : "",
        email : "",
        address : "",
        gender : "",
        birthday : "",
        remark : "",
        dutyPlanType : "",
        emergencyContact : "",
        bankAccount : "",
        nickName : "",
        maritalStatus : "",
        enrollId : "",
      };
      this.attendanceJson = {
        mondayAttendanceTime:[],
        tuesdayAttendanceTime:[],
        wednesdayAttendanceTime:[],
        thursdayAttendanceTime:[],
        firdayAttendanceTime:[],
        saturdayAttendanceTime:[],
        sundayAttendanceTime:[],
      }
    },
  },
};
</script>

<style lang="less">
</style>