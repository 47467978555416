import { defineComponent, ref, computed,onMounted } from "vue"
import { Upload, message } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

import i18n from "@/i18n"

export default defineComponent({
  name: "UploadImageBase",
  props: {
    url: {
      type: String,
      default: ""
    }
  },
  emits: ['new_image_url'],
  setup(props, ctx) {
    const imageUrl = ref("")
    const _url = ref(props.url)
    imageUrl.value = _url.value
  
    const show = computed(() => imageUrl.value == "")

    // upload handle
    const _upload_handle = (obj) => {
      const img = new Image(); // 创建一个Image对象
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);
        imageUrl.value = canvas.toDataURL("image/png"); // 将 Canvas 画布转换为 Base64 格式
        ctx.emit('new_image_url',imageUrl.value);
      };
      img.src = URL.createObjectURL(obj.file);
    }

    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 0.1;
      if (!isLt2M) {
        message.error('Image must smaller than 100kb!');
      }
      return isJpgOrPng && isLt2M;
    };

    const change_url = (url) => {
      imageUrl.value = url
    }
    ctx.expose({ change_url })
    return () => (
      <Upload class="photo-single-uploader" show-upload-list={false} customRequest={_upload_handle} before-upload={beforeUpload}>
        {
          show.value == false ?
            (<img src={imageUrl.value} alt={i18n.global.t('public.name.empty')}></img>) :
            (
                <div>
                  <PlusOutlined style="{fontSize:'25px'}"></PlusOutlined>
                  <div class="ant-upload-text">Upload</div>
                </div>
            )
        }
      </Upload>
    )
  }
})
