<template>
  <Row id="AttendanceLogs">
    <Col :span="24" class="title">
      <TypographyTitle>{{$t('nav.name.AttendanceLogsManage')}}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('attendance.name.search')" name="search">
                  <Input :placeholder="$t('attendance.name.search')" v-model:value="searchForm.search"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('attendance.name.department')" name="department">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="searchForm.idDep"
                      >
                      <SelectOption :value="null">{{ $t('attendance.name.select_all') }}</SelectOption>
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['departmentList']" :value="item['idDep']">{{ item['name'] }}</SelectOption>
                      </Select>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('attendance.name.dateRange')" name="dateRange">
                  <RangePicker :allowClear="false" v-model:value="rangePickerDate" @change="rangeChange"/>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8" class="text-right">
                <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>&nbsp;
                <Button type="primary" @click="exportXml">{{$t('public.name.export')}}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
        <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" rowKey="idAttLog" :loading="loading" :pagination="tablePagination" @change="chang_page">
            <template #actionTitle>
              <span>
                {{$t("public.table.action")}}
                <!-- <Button v-if="checkPermission('AttendanceLogsManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button> -->
              </span>
            </template>
            <template #action="{text}">
              <div>
                <div class="pc-display">
                  <Button type="default" size="small" @click="detail(text)">{{$t("public.name.detail")}}</Button>
                  <!-- <span>&nbsp;</span>
                  <Button v-if="checkPermission('AttendanceLogsManage.Edit')" type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('AttendanceLogsManage.Delete')" type="primary" danger size="small" @click="del(text.idAttLog)">{{$t("public.name.del")}}</Button> -->
                </div>
                <Dropdown :trigger="['click']" class="wap-display">
                  <template #overlay>
                    <Menu>
                      <MenuItem @click="del(text.id)">{{$t('public.name.del')}}</MenuItem>
                    </Menu>
                  </template>
                  <Button size="small">
                    {{$t("public.name.more")}}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <AttendanceLogsDetail ref="refAttendanceLogsDetail"></AttendanceLogsDetail>
    <AttendanceLogsEdit ref="refAttendanceLogsEdit" @updateList="get_list"></AttendanceLogsEdit>
  </Row>
</template>

<script>
import { createVNode } from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message, RangePicker } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { AttendanceLogsList } from "@/api/AttendanceLogsList";
import { AttendanceLogsDelete } from "@/api/AttendanceLogsDelete"
import { DepartmentListAll } from "@/api/DepartmentListAll";
import { AttendanceLogsExport } from "@/api/AttendanceLogsExport";
import AttendanceLogsDetail from "@/components/Home/AttendanceLogsDetail";
import AttendanceLogsEdit from "@/components/Home/AttendanceLogsEdit";
import moment from "moment";

export default {
  name: "AttendanceLogsManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    AttendanceLogsDetail,
    AttendanceLogsEdit,
    RangePicker,
  },
  data() {
    return {
      rangePickerDate:[null,null],
      searchForm: {
        search: "",
        startDate: null,
        endDate: null,
        idDep: null,
      },
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
          { title: this.$t("attendanceLogs.table.id_att_log"), fixed: "left", dataIndex: "idAttLog", key: "idAttLog" },
          { title: this.$t("attendanceLogs.table.id_emp"), dataIndex: "employeeNickName", key: "employeeNickName" },
          { title: this.$t("attendanceLogs.table.check_time"), dataIndex: "checkTime", key: "checkTime", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD HH:mm:ss')}},
          { title: this.$t("attendanceLogs.table.verify_mode"), dataIndex: "verifyMode", key: "verifyMode" },
        { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      selectList:{
        employeeList: [],
        departmentList: [],
      }
    };
  },
  methods: {
    chang_page(pagination) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new AttendanceLogsList();
      const getData = {
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      }
      if (this.searchForm.search != "") getData.search = this.searchForm.search
      if (this.searchForm.idDep != "") getData.idDep = this.searchForm.idDep
      if (this.searchForm.startDate != "") getData.startDate = this.searchForm.startDate
      if (this.searchForm.endDate != "") getData.endDate = this.searchForm.endDate
      api
        .get(getData)
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            const dataTmp = response.data.data;
            this.tablePagination.current = parseInt(dataTmp.current);
            this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
            this.tablePagination.total = parseInt(dataTmp.total);
            this.list = dataTmp.data;
            //如果當前處於不是第一頁，但是又沒有數據的情況，則把頁面設置為1，並重新請求
            if(this.list.length == 0 && this.tablePagination.current != 1){
              this.tablePagination.current = 1;
              this.get_list();
            }
          } else {
            this.list = [];
          }
        });
    },
    add() {
      this.$refs.refAttendanceLogsEdit.addShow();
    },
    detail(obj) {
      this.$refs.refAttendanceLogsDetail.detailShow(obj);
    },
    edit(obj) {
      this.$refs.refAttendanceLogsEdit.editShow(obj);
    },
    exportXml() {
      const api = new AttendanceLogsExport();
      const getData = {
      }
      if (this.searchForm.search != "") getData.search = this.searchForm.search
      getData.status = this.searchForm.status
      if (this.searchForm.idDep != "") getData.idDep = this.searchForm.idDep
      if (this.searchForm.idRol != "") getData.idRol = this.searchForm.idRol
      if (this.searchForm.idAnnLeaPla != "") getData.idAnnLeaPla = this.searchForm.idAnnLeaPla
      if (this.searchForm.dutyPlanType != "") getData.dutyPlanType = this.searchForm.dutyPlanType
      if (this.searchForm.startDate != "") getData.startDate = this.searchForm.startDate
      if (this.searchForm.endDate != "") getData.endDate = this.searchForm.endDate
      api
        .download(getData)
        .then((response) => {
          // console.log(decodeURI(res.headers['filename']));
          let blob = new Blob([response.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          link.download = decodeURI(response.headers['filename']); // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        });
    },
    del(idAttLog) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new AttendanceLogsDelete();
          api.post({ idAttLog: idAttLog }).then((response) => {
            if (0 === parseInt(response.data.code)) {
              this.get_list();
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
        },
      });
    },
    getDepartmentList() {
      const api = new DepartmentListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.departmentList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    rangeChange(dates){
      this.searchForm.startDate = dates[0].format("YYYY-MM-DD")
      this.searchForm.endDate = dates[1].format("YYYY-MM-DD")
    },
    search_submit() {
      this.get_list()
    },
    search_reset() {
      this.rangePickerDate = [null,null];
      this.searchForm = {
        search: "",
        startDate: null,
        endDate: null,
        idDep: null,
      };
      this.get_list()
    }
  },
  mounted() {
    this.get_list();
    this.getDepartmentList();
  },
}
</script>

<style lang="less">
@import url("~@/assets/less/home/attendanceLogs.less");
</style>