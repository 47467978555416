<template>
  <Modal
    id="RemainingVacationEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="remainingVacationForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <!-- <Col span="10">
                    <FormItem :label="$t('remainingVacation.field.id_emp')" name="idEmp">
                      
                      <Select
                              v-model:value="form.idEmp"
                              :placeholder="$t('remainingVacation.validate.required.id_emp')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['employeeList']" :value="item['idEmp']">{{ item['nameTw'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col> -->
                <!-- <Col span="10">
                    <FormItem :label="$t('remainingVacation.field.annual_leave_days')" name="annualLeaveDays">
                      <Input :placeholder="$t('remainingVacation.validate.required.annual_leave_days')"
                             v-model:value="form.annualLeaveDays">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('remainingVacation.field.maternity_leave_days')" name="maternityLeaveDays">
                      <Input :placeholder="$t('remainingVacation.validate.required.maternity_leave_days')"
                             v-model:value="form.maternityLeaveDays">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('remainingVacation.field.paternity_leave_days')" name="paternityLeaveDays">
                      <Input :placeholder="$t('remainingVacation.validate.required.paternity_leave_days')"
                             v-model:value="form.paternityLeaveDays">
                      </Input>
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('remainingVacation.field.remaining_annual_leave_days')" name="remainingAnnualLeaveDays">
                      <InputNumber :placeholder="$t('remainingVacation.validate.required.remaining_annual_leave_days')"
                      :formatter="value => value + '天'"
                          :parser="value => value.replace('天', '')"
                             v-model:value="form.remainingAnnualLeaveDays"
                             @change="(val) => {
                                if(!val){
                                  this.form.remainingAnnualLeaveDays = 0
                                }
                              }">
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('remainingVacation.field.remaining_maternity_leave_days')" name="remainingMaternityLeaveDays">
                      <InputNumber :placeholder="$t('remainingVacation.validate.required.remaining_maternity_leave_days')"
                      :formatter="value => value + '天'"
                          :parser="value => value.replace('天', '')"
                             v-model:value="form.remainingMaternityLeaveDays"
                             @change="(val) => {
                                if(!val){
                                  this.form.remainingMaternityLeaveDays = 0
                                }
                              }">
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('remainingVacation.field.remaining_paternity_leave_days')" name="remainingPaternityLeaveDays">
                      <InputNumber :placeholder="$t('remainingVacation.validate.required.remaining_paternity_leave_days')"
                      :formatter="value => value + '天'"
                          :parser="value => value.replace('天', '')"
                             v-model:value="form.remainingPaternityLeaveDays"
                             @change="(val) => {
                                if(!val){
                                  this.form.remainingPaternityLeaveDays = 0
                                }
                              }">
                      </InputNumber>
                  </FormItem>
                </Col>

                <Col span="10">
                    <FormItem :label="$t('remainingVacation.field.remaining_birthday_leave_days')" name="remainingBirthdayLeaveDays">
                      <InputNumber :placeholder="$t('remainingVacation.validate.required.remaining_birthday_leave_days')"
                      :formatter="value => value + '天'"
                          :parser="value => value.replace('天', '')"
                             v-model:value="form.remainingBirthdayLeaveDays"
                             @change="(val) => {
                                if(!val){
                                  this.form.remainingBirthdayLeaveDays = 0
                                }
                              }">
                      </InputNumber>
                  </FormItem>
                </Col>
                
                <Col span="10">
                    <FormItem :label="$t('remainingVacation.field.remark')" name="remark">
                      <Textarea
                              v-model:value="form.remark"
                              :placeholder="$t('remainingVacation.validate.required.remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('remainingVacation.field.year')" name="year">
                      <Input disabled :placeholder="$t('remainingVacation.validate.required.year')"
                             v-model:value="form.year">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('remainingVacation.field.remaining_sick_leave_days')" name="remainingSickLeaveDays">
                      <InputNumber :placeholder="$t('remainingVacation.validate.required.remaining_sick_leave_days')"
                      :formatter="value => value + '天'"
                          :parser="value => value.replace('天', '')"
                             v-model:value="form.remainingSickLeaveDays"
                             @change="(val) => {
                                if(!val){
                                  this.form.remainingSickLeaveDays = 0
                                }
                              }">
                      </InputNumber>
                  </FormItem>
                </Col>
                
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { RemainingVacationAdd } from "@/api/RemainingVacationAdd";
import { RemainingVacationEdit } from "@/api/RemainingVacationEdit";
import { RemainingGetById } from "@/api/RemainingGetById";
import { EmployeeListAll } from "@/api/EmployeeListAll"


export default {
  name: "RemainingVacationEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idRemVac : "",
        idEmp : "",
        annualLeaveDays : "",
        maternityLeaveDays : "",
        paternityLeaveDays : "",
        remainingAnnualLeaveDays : "",
        remainingMaternityLeaveDays : "",
        remainingPaternityLeaveDays : "",
        year : "",
        remark : "",
        remainingSickLeaveDays : "",
      },
      fileList: {
      },
      selectList: {
        employeeList: [],
      },
      validateRules: {
        idRemVac: [
          {
            required: true,
            message: this.$t("remainingVacation.validate.required.id_rem_vac"),
            trigger: "change",
          },
        ],
        idEmp: [
          {
            required: true,
            message: this.$t("remainingVacation.validate.required.id_emp"),
            trigger: "change",
          },
        ],
        annualLeaveDays: [
          {
            required: true,
            message: this.$t("remainingVacation.validate.required.annual_leave_days"),
            trigger: "change",
          },
        ],
        maternityLeaveDays: [
          {
            required: true,
            message: this.$t("remainingVacation.validate.required.maternity_leave_days"),
            trigger: "change",
          },
        ],
        paternityLeaveDays: [
          {
            required: true,
            message: this.$t("remainingVacation.validate.required.paternity_leave_days"),
            trigger: "change",
          },
        ],
        remainingAnnualLeaveDays: [
          {
            required: true,
            message: this.$t("remainingVacation.validate.required.remaining_annual_leave_days"),
            trigger: "change",
            type: "number",
          },
        ],
        remainingMaternityLeaveDays: [
          {
            required: true,
            message: this.$t("remainingVacation.validate.required.remaining_maternity_leave_days"),
            trigger: "change",
            type: "number",
          },
        ],
        remainingPaternityLeaveDays: [
          {
            required: true,
            message: this.$t("remainingVacation.validate.required.remaining_paternity_leave_days"),
            trigger: "change",
            type: "number",
          },
        ],
        year: [
          {
            required: true,
            message: this.$t("remainingVacation.validate.required.year"),
            trigger: "change",
          },
        ],
        remark: [
          {
            required: false,
            message: this.$t("remainingVacation.validate.required.remark"),
            trigger: "change",
          },
        ],
        remainingSickLeaveDays: [
          {
            required: true,
            message: this.$t("remainingVacation.validate.required.remaining_sick_leave_days"),
            trigger: "change",
	          type: "number",
          },
        ], 
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getEmployeeList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      // this.form = JSON.parse(JSON.stringify(obj));
      // 因为现在列表的数据和日期对齐，所以此处，如果要编辑，则从后台重新获取
      this.getRemainingVacation(obj.idRemVac);
      this.getEmployeeList();
      this.visible = true;
    },
    submit() {
      this.$refs["remainingVacationForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new RemainingVacationAdd();
              break;
            case "edit":
              console.log("edit");
              api = new RemainingVacationEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getEmployeeList() {
      const api = new EmployeeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.employeeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },

    getRemainingVacation(idRemVac) {
      const api = new RemainingGetById();
      api.get({
        idRemVac: idRemVac
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.form= response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    // reset data
    _resetData() {
      this.form = {
        idRemVac : "",
        idEmp : "",
        annualLeaveDays : "",
        maternityLeaveDays : "",
        paternityLeaveDays : "",
        remainingAnnualLeaveDays : "",
        remainingMaternityLeaveDays : "",
        remainingPaternityLeaveDays : "",
        year : "",
        remark : "",
        remainingSickLeaveDays : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>