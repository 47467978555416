<template>
  <Modal
    id="LeaveEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="leaveForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                    <FormItem :label="$t('leave.field.id_emp')" name="idEmp">
                      <!--关联表的下拉选择-->
                      <Select :disabled="actionType === 'edit'"
                              v-model:value="form.idEmp"
                              :placeholder="$t('leave.validate.required.id_emp')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                              @change="changeEmp"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['employeeList']" :value="item['idEmp']">{{ item['nickName'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('leave.field.id_lea_typ')" name="idLeaTyp">
                      <!--关联表的下拉选择-->
                      <Select :disabled="actionType === 'edit'"
                              v-model:value="form.idLeaTyp"
                              :placeholder="$t('leave.validate.required.id_lea_typ')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['leaveTypeList']" :value="item['idLeaTyp']">{{ item['leaveTypeName'] }}</SelectOption>
                      </Select>
                      <span v-if="form.idLeaTyp === 1">餘: {{remainingVacation.remainingAnnualLeaveDays}} 天</span>
                      <span v-if="form.idLeaTyp === 2">餘: {{remainingVacation.remainingMaternityLeaveDays}} 天</span>
                      <span v-if="form.idLeaTyp === 3">餘: {{remainingVacation.remainingPaternityLeaveDays}} 天</span>
                      <span v-if="form.idLeaTyp === 4">餘: {{remainingVacation.remainingSickLeaveDays}} 天</span>
                      
                  </FormItem>
                </Col>
                
                <!-- <Col span="10">
                    <FormItem :label="$t('leave.field.leave_type')" name="leaveType">
                      <Select
                              v-model:value="form.leaveType"
                              :placeholder="$t('leave.validate.required.leave_type')"
                              :options="selectList.leaveTypeList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('leave.field.start_time')" name="startTime">
                      <DatePicker :disabledDate="disabledDate" valueFormat="YYYY-MM-DD" v-model:value="form.startTime" :popupStyle="{ zIndex: 9999999950 }" @change="changeTimeUnit"></DatePicker>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('leave.field.end_time')" name="endTime">
                      <DatePicker :disabledDate="disabledDate" valueFormat="YYYY-MM-DD" v-model:value="form.endTime" :popupStyle="{ zIndex: 9999999950 }" @change="changeTimeUnit"></DatePicker>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'edit'">
                    <FormItem :label="$t('leave.field.status')" name="status">
                      <Select
                              v-model:value="form.status"
                              :placeholder="$t('leave.validate.required.status')"
                              :options="selectList.statusList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                </Col>
                <!-- <Col span="10">
                    <FormItem :label="$t('leave.field.approver_id')" name="approverId">
                      
                      <Select
                              v-model:value="form.approverId"
                              :placeholder="$t('leave.validate.required.approver_id')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['employeeList']" :value="item['idEmp']">{{ item['nameTw'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('leave.field.approver2_id')" name="approver2Id">
                      
                      <Select
                              v-model:value="form.approver2Id"
                              :placeholder="$t('leave.validate.required.approver2_id')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['employeeList']" :value="item['idEmp']">{{ item['nameTw'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('leave.field.reason')" name="reason">
                      <Textarea
                              v-model:value="form.reason"
                              :placeholder="$t('leave.validate.required.reason')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                </Col>
                <!-- <Col span="10">
                    <FormItem :label="$t('leave.field.picture_url')" ref="pictureUrl" name="pictureUrl">
                      <UploadImage :url="form.pictureUrl"
                                   @new_image_url="(value) => {
                                      this.$refs.pictureUrl.clearValidate()
                                      this.form.pictureUrl = value
                                   }"
                                   ref="upload_pictureUrl"
                      />
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('leave.field.remark')" name="remark">
                      <Textarea
                              v-model:value="form.remark"
                              :placeholder="$t('leave.validate.required.remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('leave.field.reject_reason')" name="rejectReason">
                      <Textarea
                              v-model:value="form.rejectReason"
                              :placeholder="$t('leave.validate.required.reject_reason')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                </Col>
                <!-- <Col span="10">
                    <FormItem :label="$t('leave.field.submit_time')" name="submitTime">
                      <DatePicker show-time valueFormat="YYYY-MM-DD HH:mm:ss" v-model:value="form.submitTime" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('leave.field.time_unit')" name="timeUnit">
                      <Select
                              v-model:value="form.timeUnit"
                              :placeholder="$t('leave.validate.required.time_unit')"
                              :options="selectList.timeUnitList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                              @change="changeTimeUnit"
                              :disabled="isDisableTimeUnit"
                      >
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('leave.field.duration')" name="duration">
                      <InputNumber disabled :placeholder="$t('leave.validate.required.duration')" :step="form.timeUnit"
                             v-model:value="form.duration">
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10" v-if="form.timeUnit === 0.25 || form.timeUnit === 0.50">
                    <FormItem :label="$t('leave.field.time_frame')" name="timeFrame">
                      <Select
                              v-model:value="form.timeFrame"
                              :placeholder="$t('leave.validate.required.time_frame')"
                              :options="selectList.timeFrameList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { LeaveAdd } from "@/api/LeaveAdd";
import { LeaveEdit } from "@/api/LeaveEdit";
import { EmployeeListAll } from "@/api/EmployeeListAll"
import { LeaveTypeListAll } from "@/api/LeaveTypeListAll"
import { RemainingGetByIdEmp } from "@/api/RemainingGetByIdEmp";
import { LeaveGetRealLeaveDays } from "@/api/LeaveGetRealLeaveDays";
import UploadImage from "@/components/Common/UploadImage"
import moment from "moment"


export default {
  name: "LeaveEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    UploadImage,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      timeOptions:{
        format:"HH:mm",
        minuteStep:30
      },
      isDisableTimeUnit:false,
      form: {
        idLea : "",
        idEmp : "",
        idLeaTyp : "",
        leaveType : "",
        startTime : "",
        endTime : "",
        reason : "",
        pictureUrl : "",
        remark : "",
        status : "",
        rejectReason : "",
        submitTime : "",
        timeFrame : "",
        timeUnit : "",
      },
      remainingVacation: {},
      fileList: {
      },
      selectList: {
        employeeList: [],
        leaveTypeList: [],
        //leaveTypeList: [{value: 1,label: '調休'},{value: 2,label: '年假'},{value: 3,label: '產假'},{value: 4,label: '侍產假'},{value: 5,label: '事假'},{value: 6,label: '病假'},{value: 7,label: '婚假'}],
        timeFrameList: [{value: 1,label: 'AM'},{value: 2,label: 'PM'}],
        timeUnitList: [{value: 0.25,label: '0.25'},{value: 0.50,label: '0.50'},{value: 1.00,label: '1.00'}],
        statusList: [{value: 0,label: '待發送'},{value: 1,label: '待審批'},{value: 2,label: '已拒絕'},{value: 3,label: '已通過'}],
      },
      validateRules: {
        idLea: [
          {
            required: true,
            message: this.$t("leave.validate.required.id_lea"),
            trigger: "change",
          },
        ],
        idEmp: [
          {
            required: true,
            message: this.$t("leave.validate.required.id_emp"),
            trigger: "change",
            type: "number",
          },
        ],
        idLeaTyp: [
          {
            required: true,
            message: this.$t("leave.validate.required.id_lea_typ"),
            trigger: "change",
            type: "number",
          },
        ],
        leaveType: [
          {
            required: true,
            message: this.$t("leave.validate.required.leave_type"),
            trigger: "change",
            type: "number",
          },
        ],
        startTime: [
          {
            required: true,
            message: this.$t("leave.validate.required.start_time"),
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            message: this.$t("leave.validate.required.end_time"),
            trigger: "change",
          },
        ],
        duration: [
          {
            required: true,
            message: this.$t("leave.validate.required.duration"),
            trigger: "change",
            min: 0.25,
            type: "number",
          },
        ],
        reason: [
          {
            required: false,
            message: this.$t("leave.validate.required.reason"),
            trigger: "change",
          },
        ],
        pictureUrl: [
          {
            required: false,
            message: this.$t("leave.validate.required.picture_url"),
            trigger: "change",
          },
        ],
        approverId: [
          {
            required: true,
            message: this.$t("leave.validate.required.approver_id"),
            trigger: "change",
            type: "number",
          },
        ],
        approveTime: [
          {
            required: true,
            message: this.$t("leave.validate.required.approve_time"),
            trigger: "change",
          },
        ],
        cc: [
          {
            required: true,
            message: this.$t("leave.validate.required.cc"),
            trigger: "change",
          },
        ],
        remark: [
          {
            required: false,
            message: this.$t("leave.validate.required.remark"),
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("leave.validate.required.status"),
            trigger: "change",
            type: "number",
          },
        ],
        approver2Id: [
          {
            required: false,
            message: this.$t("leave.validate.required.approver2_id"),
            trigger: "change",
            type: "number",
          },
        ],
        approve2Time: [
          {
            required: true,
            message: this.$t("leave.validate.required.approve2_time"),
            trigger: "change",
          },
        ],
        rejectReason: [
          {
            required: false,
            message: this.$t("leave.validate.required.reject_reason"),
            trigger: "change",
          },
        ],
        submitTime: [
          {
            required: true,
            message: this.$t("leave.validate.required.submit_time"),
            trigger: "change",
          },
        ],
        timeFrame: [
          {
            required: true,
            message: this.$t("leave.validate.required.time_frame"),
            trigger: "change",
            type: "number",
          },
        ],
        timeUnit: [
          {
            required: true,
            message: this.$t("leave.validate.required.time_unit"),
            trigger: "change",
            type: 'number'
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getEmployeeList();
      this.getLeaveTypeList();
      if (this.$refs.upload_pictureUrl) {
          this.$refs.upload_pictureUrl.change_url("")
      }
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      if (this.$refs.upload_pictureUrl) {
          this.$refs.upload_pictureUrl.change_url("")
      }
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getEmployeeList();
      this.getLeaveTypeList();
        if (this.$refs.upload_pictureUrl) {
            this.$refs.upload_pictureUrl.change_url(this.form.pictureUrl)
        }
      this.visible = true;
    },
    submit() {
      this.$refs["leaveForm"]
        .validate()
        .then(() => {
          //判断开始日期是否大于结束日期
          // if(!this.form.startTime.isBefore(this.form.endTime)){
          //   message.error("开始时间必须小于结束时间");
          //   return;
          // }


          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new LeaveAdd();
              break;
            case "edit":
              console.log("edit");
              api = new LeaveEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getEmployeeList() {
      const api = new EmployeeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.employeeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    getRealLeaveDays() {
      const api = new LeaveGetRealLeaveDays();
      api.get({
        idEmp: this.form.idEmp,
        startTime: this.form.startTime,
        endTime: this.form.endTime
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.form.duration = response.data.data
        } else {
            message.error(response.data.msg);
        }
      });
    },
    getLeaveTypeList() {
      const api = new LeaveTypeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.leaveTypeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    disabledDate(current) {
      // Can not select days before today and today
      // return current && current <= moment().startOf('day');
      return false;
    },
    getRemainingVacation() {
      let api = new RemainingGetByIdEmp();
      api
        .get({
          idEmp: this.form.idEmp,
        })
        .then((response, any) => {
          if (0 == parseInt(response.data.code)) {
            this.remainingVacation = response.data.data;
          } else {
            message.error(response.data.msg);
          }
        });
    },
    changeEmp() {
      this.getRemainingVacation()
    },
        getRemainingVacation() {
      let api = new RemainingGetByIdEmp();
      api
        .get({
          idEmp: this.form.idEmp,
        })
        .then((response, any) => {
          if (0 == parseInt(response.data.code)) {
            this.remainingVacation = response.data.data;
          } else {
            message.error(response.data.msg);
          }
        });
    },
    changeTimeUnit(){
      if(!this.form.idEmp || !this.form.startTime || !this.form.endTime){
        return
      }
      var days = moment(this.form.endTime).diff(moment(this.form.startTime),'day')
      if(days === 0){
        if(this.form.timeUnit === 0.25){
          this.form.duration = 0.25
        }else if(this.form.timeUnit === 0.5){
          this.form.duration = 0.5
        }else{
          this.form.duration = 1
        }
        this.isDisableTimeUnit = false
      }else{
        this.form.timeUnit = 1
        // this.form.duration = days + 1
        this.getRealLeaveDays()
        this.isDisableTimeUnit = true
      }

      
    },
    // reset data
    _resetData() {
      this.form = {
        idLea : "",
        idEmp : "",
        idLeaTyp : "",
        leaveType : "",
        startTime : "",
        endTime : "",
        reason : "",
        pictureUrl : "",
        remark : "",
        status : "",
        rejectReason : "",
        submitTime : "",
        timeFrame : "",
        timeUnit : "",
        isDisableTimeUnit:false,
      };
    },
  },
};
</script>

<style lang="less">
</style>