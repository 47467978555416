<template>
  <Modal
    width="100%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="$t('generalSalary.name.title')"
    :maskClosable="false"
    :keyboard="false"
  >
    <Row id="GeneralSalary">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="4">
                <FormItem :label="$t('generalSalary.name.month')">
                  <MonthPicker valueFormat="YYYY-MM" :placeholder="$t('generalSalary.name.month_placeholder')" v-model:value="searchForm.month" :popupStyle="{ zIndex: 9999999950 }"></MonthPicker>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="4">
                <Button type="primary" @click="general">{{$t('generalSalary.name.generalSalary')}}</Button>&nbsp;
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
        <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" rowKey="idEmp" :loading="loading" :pagination="false">
          <template #allowance="{ text, record }">
            <div class="editable-cell">
              <div v-if="editableData[record.idEmp+'allowance']" class="editable-cell-input-wrapper">
                <InputNumber v-model:value="editableData[record.idEmp+'allowance'].allowance" @pressEnter="save(record.idEmp,'allowance')" 
                :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.idEmp,'allowance')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                <EditOutlined class="editable-cell-icon" @click="edit(record.idEmp,'allowance')" />
              </div>
            </div>
          </template>
          <template #otherSubsidy="{ text, record }">
            <div class="editable-cell">
              <div v-if="editableData[record.idEmp+'otherSubsidy']" class="editable-cell-input-wrapper">
                <InputNumber v-model:value="editableData[record.idEmp+'otherSubsidy'].otherSubsidy" @pressEnter="save(record.idEmp,'otherSubsidy')" 
                :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.idEmp,'otherSubsidy')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                <EditOutlined class="editable-cell-icon" @click="edit(record.idEmp,'otherSubsidy')" />
              </div>
            </div>
          </template>
          <template #commission="{ text, record }">
            <div class="editable-cell">
              <div v-if="editableData[record.idEmp+'commission']" class="editable-cell-input-wrapper">
                <InputNumber v-model:value="editableData[record.idEmp+'commission'].commission" @pressEnter="save(record.idEmp,'commission')" 
                :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.idEmp,'commission')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                <EditOutlined class="editable-cell-icon" @click="edit(record.idEmp,'commission')" />
              </div>
            </div>
          </template>
          <template #overtimePay="{ text, record }">
            <div class="editable-cell">
              <div v-if="editableData[record.idEmp+'overtimePay']" class="editable-cell-input-wrapper">
                <InputNumber v-model:value="editableData[record.idEmp+'overtimePay'].overtimePay" @pressEnter="save(record.idEmp,'overtimePay')" 
                :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.idEmp,'overtimePay')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                <EditOutlined class="editable-cell-icon" @click="edit(record.idEmp,'overtimePay')" />
              </div>
            </div>
          </template>
          <template #bonus="{ text, record }">
            <div class="editable-cell">
              <div v-if="editableData[record.idEmp+'bonus']" class="editable-cell-input-wrapper">
                <InputNumber v-model:value="editableData[record.idEmp+'bonus'].bonus" @pressEnter="save(record.idEmp,'bonus')" 
                :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.idEmp,'bonus')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                <EditOutlined class="editable-cell-icon" @click="edit(record.idEmp,'bonus')" />
              </div>
            </div>
          </template>
          <template #otherDeductions="{ text, record }">
            <div class="editable-cell">
              <div v-if="editableData[record.idEmp+'otherDeductions']" class="editable-cell-input-wrapper">
                <InputNumber v-model:value="editableData[record.idEmp+'otherDeductions'].otherDeductions" @pressEnter="save(record.idEmp,'otherDeductions')" 
                :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.idEmp,'otherDeductions')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                <EditOutlined class="editable-cell-icon" @click="edit(record.idEmp,'otherDeductions')" />
              </div>
            </div>
          </template>

          <template #mpf="{ text, record }">
            <div class="editable-cell">
              <div v-if="editableData[record.idEmp+'mpf']" class="editable-cell-input-wrapper">
                <InputNumber v-model:value="editableData[record.idEmp+'mpf'].mpf" @pressEnter="save(record.idEmp,'mpf')" 
                :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.idEmp,'mpf')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                <EditOutlined class="editable-cell-icon" @click="edit(record.idEmp,'mpf')" />
              </div>
            </div>
          </template>
          <template #employerMpf="{ text, record }">
            <div class="editable-cell">
              <div v-if="editableData[record.idEmp+'employerMpf']" class="editable-cell-input-wrapper">
                <InputNumber v-model:value="editableData[record.idEmp+'employerMpf'].employerMpf" @pressEnter="save(record.idEmp,'employerMpf')" 
                :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.idEmp,'employerMpf')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                <EditOutlined class="editable-cell-icon" @click="edit(record.idEmp,'employerMpf')" />
              </div>
            </div>
          </template>
          <template #remark="{ text, record }">
            <div class="editable-cell">
              <div v-if="editableData[record.idEmp+'remark']" class="editable-cell-input-wrapper">
                <Input v-model:value="editableData[record.idEmp+'remark'].remark" @pressEnter="save(record.idEmp,'remark')" 
                />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.idEmp,'remark')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ text }}
                <EditOutlined class="editable-cell-icon" @click="edit(record.idEmp,'remark')" />
              </div>
            </div>
          </template>
            <template #actionTitle>
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('ReportManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
  </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Table,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  MonthPicker
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons-vue";
import { GeneralSalary } from "@/api/GeneralSalary"
import { cloneDeep } from 'lodash-es';
import { ReportBatchAdd } from "@/api/ReportBatchAdd"
import { MpfSettingListAll } from "@/api/MpfSettingListAll"
import moment from "moment";


export default {
  name: "EmployeeEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    EditOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    Table,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    MonthPicker
  },
  data() {
    return {
      visible: false,
      searchForm: {
        month:""
      },
      mpfSettingList:[],
      loading: false,
      list: [],
      editableData: {

      },
      columns: [
          // { title: this.$t("report.table.id_rep"), fixed: "left", dataIndex: "idRep", key: "idRep" },
          { title: this.$t("report.table.id_emp"), width: 100, fixed: "left", dataIndex: "employeeNickName", key: "employeeNickName" },
          { title: this.$t("report.table.month"), width: 100, fixed: "left", dataIndex: "month", key: "month" },
          { title: this.$t("report.table.base_salary"), width: 100, dataIndex: "baseSalary", key: "baseSalary", customRender: ({text}) =>{
              return '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            } 
          },
          { title: this.$t("report.table.allowance"), width: 100, dataIndex: "allowance", key: "allowance", slots: {
              customRender: "allowance"
          }
          },
          // { title: this.$t("report.table.car_subsidy"), width: 100, dataIndex: "carSubsidy", key: "carSubsidy", customRender: ({text}) =>{
          //     return '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          //   } 
          // },
          // { title: this.$t("report.table.house_subsidy"), width: 100, dataIndex: "houseSubsidy", key: "houseSubsidy", customRender: ({text}) =>{
          //     return '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          //   } 
          // },
          { title: this.$t("report.table.commission"), width: 150, dataIndex: "commission", key: "commission", slots:{
              customRender: "commission"
            } 
          },
          
          { title: this.$t("report.table.bonus"), width: 150, dataIndex: "bonus", key: "bonus", slots:{
              customRender: "bonus"
            } 
          },
          { title: this.$t("report.table.other_subsidy"), width: 150, dataIndex: "otherSubsidy", key: "otherSubsidy", slots:{
              customRender: "otherSubsidy"
            } 
          },
          { title: this.$t("report.table.overtime_times"), width: 150, dataIndex: "overtimeTimes", key: "overtimeTimes", customRender: ({text,record}) =>{
              return text + "次"
            }
          },
          { title: this.$t("report.table.overtime_pay"), width: 150, dataIndex: "overtimePay", key: "overtimePay", slots:{
              customRender: "overtimePay"
            } 
          },
          { title: this.$t("report.table.overtime"), width: 150, dataIndex: "overtime", key: "overtime", customRender: ({text,record}) =>{
              return text + "小時"
            } },
          
          // { title: this.$t("report.table.compensatory_leave_time"), width: 100, dataIndex: "compensatoryLeaveTime", key: "compensatoryLeaveTime", customRender: ({text,record}) =>{
          //     return text + "分鐘"
          //   }
          // },
          { title: this.$t("report.table.total_use_annual_leave"), width: 100, dataIndex: "totalUseAnnualLeave", key: "totalUseAnnualLeave", customRender: ({text,record}) =>{
              // if(text === 0){
              //   return '0天'
              // }
              // var days = Math.floor(text / 480);
              // var hours = Math.floor((text % 480) / 60);
              // var minutes = text % 60;
              // var result = "";
              // if (days !== 0) {
              //   result = days + "天";
              // }
              // if (hours !== 0) {
              //   result = result + hours + "小時";
              // }
              // if (minutes !== 0) {
              //   result = result + minutes + "分鐘";
              // }
              // return result;
              return text + '天'
            }
          },
          { title: this.$t("report.table.total_annual_leave"), width: 100, dataIndex: "totalAnnualLeave", key: "totalAnnualLeave", customRender: ({text,record}) =>{
              return text + "天"
            }
          },
          { title: this.$t("report.table.use_annual_leave"), width: 100, dataIndex: "useAnnualLeave", key: "useAnnualLeave", customRender: ({text,record}) =>{
              // if(text === 0){
              //   return '0天'
              // }
              // var days = Math.floor(text / 480);
              // var hours = Math.floor((text % 480) / 60);
              // var minutes = text % 60;
              // var result = "";
              // if (days !== 0) {
              //   result = days + "天";
              // }
              // if (hours !== 0) {
              //   result = result + hours + "小時";
              // }
              // if (minutes !== 0) {
              //   result = result + minutes + "分鐘";
              // }
              // return result;
              return text + '天'
            }
          },
          { title: this.$t("report.table.remark"), width: 400, dataIndex: "remark", key: "remark", slots:{
              customRender: "remark"
            }  },
          { title: this.$t("report.table.leave_time"), width: 100, dataIndex: "leaveTime", key: "leaveTime", customRender: ({text,record}) =>{
              // if(text === 0){
              //   return '0天'
              // }
              // var days = Math.floor(text / 480);
              // var hours = Math.floor((text % 480) / 60);
              // var minutes = text % 60;
              // var result = "";
              // if (days !== 0) {
              //   result = days + "天";
              // }
              // if (hours !== 0) {
              //   result = result + hours + "小時";
              // }
              // if (minutes !== 0) {
              //   result = result + minutes + "分鐘";
              // }
              // return result;
              return text + '天'
            }
          },
          // { title: this.$t("report.table.need_attendance_days"), width: 100, dataIndex: "needAttendanceDays", key: "needAttendanceDays" },
          // { title: this.$t("report.table.absence_days"), width: 100, dataIndex: "absenceDays", key: "absenceDays" },
          // { title: this.$t("report.table.create_time"), width: 100, dataIndex: "createTime", key: "createTime" },
          { title: this.$t("report.table.mpf_start_date"), width: 100, dataIndex: "mpfStartDate", key: "mpfStartDate" },
          { title: this.$t("report.table.mpf"), width: 150, dataIndex: "mpf", key: "mpf",slots:{ 
              customRender: "mpf"
            } 
          },
          { title: this.$t("report.table.employer_mpf"), width: 150, dataIndex: "employerMpf", key: "employerMpf",slots:{ 
              customRender: "employerMpf"
            } 
          },
          { title: this.$t("report.table.other_deductions"), width: 150, dataIndex: "otherDeductions", key: "otherDeductions", slots:{
              customRender: "otherDeductions"
            } 
          },
          // { title: this.$t("report.table.attendance_days"), width: 100, dataIndex: "attendanceDays", key: "attendanceDays" },
          // { title: this.$t("report.table.rest_days"), width: 100, dataIndex: "restDays", key: "restDays" },
          // { title: this.$t("report.table.work_hours"), width: 100, dataIndex: "workHours", key: "workHours", customRender: ({text,record}) =>{
          //     // if(text === 0){
          //     //   return '0天'
          //     // }
          //     // var days = Math.floor(text / 480);
          //     // var hours = Math.floor((text % 480) / 60);
          //     // var minutes = text % 60;
          //     // var result = "";
          //     // if (days !== 0) {
          //     //   result = days + "天";
          //     // }
          //     // if (hours !== 0) {
          //     //   result = result + hours + "小時";
          //     // }
          //     // if (minutes !== 0) {
          //     //   result = result + minutes + "分鐘";
          //     // }
          //     // return result;
          //     return text + '天'
          //   }
          // },
          { title: this.$t("report.table.late_time"), width: 100, dataIndex: "lateTime", key: "lateTime" },
          { title: this.$t("report.table.late_times"), width: 100, dataIndex: "lateTimes", key: "lateTimes" },
          // { title: this.$t("report.table.serious_late_times"), width: 100, dataIndex: "seriousLateTimes", key: "seriousLateTimes" },
          // { title: this.$t("report.table.serious_late_time"), width: 100, dataIndex: "seriousLateTime", key: "seriousLateTime" },
          // { title: this.$t("report.table.absenteeism_times"), width: 100, dataIndex: "absenceDays", key: "absenceDays" },
          // { title: this.$t("report.table.early_leave_time"), width: 100, dataIndex: "earlyLeaveTime", key: "earlyLeaveTime" },
          // { title: this.$t("report.table.early_leave_times"), width: 100, dataIndex: "earlyLeaveTimes", key: "earlyLeaveTimes" },
          // { title: this.$t("report.table.no_check_in_times"), width: 100, dataIndex: "noCheckInTimes", key: "noCheckInTimes" },
          // { title: this.$t("report.table.no_check_out_times"), width: 100, dataIndex: "noCheckOutTimes", key: "noCheckOutTimes" },
          { title: this.$t("report.table.total_salary"), width: 100, fixed: "right", dataIndex: "totalSalary", key: "totalSalary", customRender: ({text}) =>{
              return '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            } 
          },
      ],
    };
  },
  computed: {
  },
  methods: {
    addShow() {
      this._resetData();
      this.getMpfSettingList();
      this.visible = true;
    },
    general() {
      this.loading = true;
      const api = new GeneralSalary();
      api
        .get({
          month: this.searchForm.month
        })
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            const dataTmp = response.data;
            
            this.list = dataTmp.data;
            
            this.list.forEach(report => {
              this.caculateTotalSalaryAndMpf(report.idEmp);
            })
            
          } else {
            this.list = [];
          }
        });
    },
    //当只修改mpf时，触发该函数
    caculateTotalSalary(idEmp){
      
      this.list.filter((report) => {
        return report.idEmp === idEmp
      }).forEach(report => {
        var realTotalSalary = report.baseSalary + report.allowance  + report.otherSubsidy + report.commission + report.overtimePay + report.bonus - report.otherDeductions;
        report.totalSalary = realTotalSalary - report.mpf;
      })
    },
    caculateTotalSalaryAndMpf(idEmp){
      var realTotalSalary = 0;
      var needMpfSalary = 0;
      this.list.filter((report)=>{
        return report.idEmp === idEmp
      }).forEach(report => {
        
        realTotalSalary = report.baseSalary + report.allowance + report.otherSubsidy + report.commission + report.overtimePay + report.bonus - report.otherDeductions;
        
        // let daysInMonth = moment(report.month, "YYYY-MM").daysInMonth();

        // needMpfSalary = realTotalSalary / daysInMonth * report.needMpfDays
        // needMpfSalary = needMpfSalary.toFixed(2)
        //先獲取公積金區間，如果找不到，按0算
        var mpfRecord = null;
        // for(var i = 0;i < this.mpfSettingList.length; i ++){
        //   if(this.mpfSettingList[i].max){
        //     if(this.mpfSettingList[i].min <= needMpfSalary && needMpfSalary < this.mpfSettingList[i].max){
        //       mpfRecord = this.mpfSettingList[i];
        //       break;
        //     }
        //   }else{
        //     if(this.mpfSettingList[i].min <= needMpfSalary){
        //       mpfRecord = this.mpfSettingList[i];
        //       break;
        //     }
        //   }
        // }
        for(var i = 0;i < this.mpfSettingList.length; i ++){
          
            if(this.mpfSettingList[i].min < realTotalSalary && realTotalSalary <= this.mpfSettingList[i].max){
              mpfRecord = this.mpfSettingList[i];
              break;
            }
          
        }
        console.log(mpfRecord);
        // console.log(needMpfSalary);
        //搜索不到記錄，mpf直接為0
        if(!mpfRecord){
          report.mpf = 0;
          report.employerMpf = 0;
          report.totalSalary = realTotalSalary - report.mpf;
          return 
        }
        //判定本月结束日期是否大于开始强积金日期是否，如果为否，则不用计算强积金
        if(moment(report.mpfStartDate).isAfter(moment(report.month).endOf('month'))){
          report.mpf = 0;
          report.employerMpf = 0;
          report.totalSalary = realTotalSalary - report.mpf;
          return 
        }


        if(mpfRecord.fixAmount !== null && mpfRecord.fixAmount !== ''){
          //如果該記錄的最大值為空，則以最小值計算mpf
          report.mpf = mpfRecord.fixAmount;
          report.employerMpf = mpfRecord.fixAmount;
        }else{
          report.mpf = (realTotalSalary * mpfRecord.percentage / 100).toFixed(2);
          report.employerMpf = (realTotalSalary * mpfRecord.percentage / 100).toFixed(2);
        }
        //判断当前月份的结束日，是否在入职的第三个月，如果是则，补交前两个月强制金，如果小于第三个月，则不交，如果大于，则交一个月
        let entryMonth = moment(report.month).endOf('month').diff(moment(report.joinDate, 'DD/MM/YYYY'),'month') + 1;
        console.log('employeeNickName:' + report.employeeNickName)
        console.log('入职月数:' + entryMonth)
        console.log('report.joinDate:' + moment(report.joinDate, 'DD/MM/YYYY').format('YYYY-MM-DD'))
        console.log('report.month:' + moment(report.month).endOf('month').format('YYYY-MM-DD'))
        if(entryMonth < 3){
          report.mpf = 0;
          report.employerMpf = 0;
        }else if(entryMonth == 3){
          report.employerMpf = report.employerMpf * 3;
        }else{
          //这种默认就是上面计算出的mpf
        }


        report.totalSalary = realTotalSalary - report.mpf;
      })
      
    },
    submit() {
      let api = new ReportBatchAdd();
      api.post({
        reportListStr: JSON.stringify(this.list)
      }).then((response, any) => {
        if (0 == parseInt(response.data.code)) {
          message.success(response.data.msg);
          this.$emit("updateList");
          this.visible = false;
        } else {
          message.error(response.data.msg);
        }
      });
       
    },
    edit(key,fieldName){
      this.editableData[key+''+fieldName] = cloneDeep(this.list.filter(item => key === item.idEmp)[0]);
    },
    getMpfSettingList() {
      const api = new MpfSettingListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.mpfSettingList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    save(key,fieldName){
      
      Object.assign(this.list.filter(item => key === item.idEmp)[0], this.editableData[key+''+fieldName]);
      delete this.editableData[key+''+fieldName];
      
      if(fieldName === 'mpf'){
        this.caculateTotalSalary(key);
      }else{
        this.caculateTotalSalaryAndMpf(key);
      }

    },
    // reset data
    _resetData() {
      this.searchForm = {
        month:""
      };
      this.list = [];
      this.form = {
      };
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/less/components/home/generalSalary.less");
</style>