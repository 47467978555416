<template>
  <Modal
    id="Prediction"
    width="70%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :confirm-loading="confirmLoading"
    :title="$t('prediction.name.title')"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="predictionForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                <FormItem :label="$t('prediction.name.date')">
                  <DatePicker v-model:value="form.date" valueFormat="YYYY-MM-DD"/>
                </FormItem>
              </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  RangePicker,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { RemainingVacationPrediction } from "@/api/RemainingVacationPrediction";
import moment from "moment";

export default {
  name: "Prediction",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    RangePicker,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      actionType: "",
      rangePickerDate:[null,null],
      form: {
        idEmp: null,
        date: null,
      },
      fileList: {},
      selectList: {
        
      },
      validateRules: {
        idEmp: [
          {
            required: true,
            message: this.$t("prediction.validate.required.id_emp"),
            trigger: "change",
            type: "number",
          },
        ],
        date: [
          {
            required: true,
            message: this.$t("prediction.validate.required.date"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    async show(idEmp) {
      this._resetData();
      this.form.idEmp = idEmp;
      this.visible = true;
    },
    submit() {
      this.$refs["predictionForm"]
        .validate()
        .then(() => {
          const api = new RemainingVacationPrediction();
          
          api.get({
            idEmp: this.form.idEmp,
            date: this.form.date
          }).then((response) => {
            if (!response) {
              return
            }
            message.info(response.data.data)
          });
        })
    },
    
    // reset data
    _resetData() {
      this.rangePickerDate = [null,null],
      this.form = {
        idEmp: null,
        date: null,
      };
    },
  },
};
</script>

<style lang="less">
</style>