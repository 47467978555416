<template>
<Layout id="OvertimeApplicationApprove" v-if="visable">
  <LayoutHeader class="home-header">
        <Row>
          <Col :offset="17" :span="1">
            <LanguageButton />
          </Col>
        </Row>
      </LayoutHeader>
      <LayoutContent class="home-content">
    <Row class="home-content">
    <Col :span="24" class="title">
      <TypographyTitle>{{ $t("nav.name.OvertimeApplicationManage") }}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Descriptions >
        <DescriptionsItem :label="$t('overtimeApplication.field.id_ove_app')">{{data.idOveApp}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.id_emp')">{{data.employeeNameTw}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.start_time')">{{data.startTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.time_remark')">{{data.timeRemark}}</DescriptionsItem>
        <!-- <DescriptionsItem :label="$t('overtimeApplication.field.end_time')">{{data.endTime}}</DescriptionsItem> -->
        <DescriptionsItem :label="$t('overtimeApplication.field.duration')">{{data.duration + '小時'}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.approver_id')">{{data.approver}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.approve_time')">{{data.approveTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.reason')">{{data.reason}}</DescriptionsItem>
      <DescriptionsItem :label="$t('overtimeApplication.field.submit_time')">{{data.submitTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('overtimeApplication.field.time_frame')" v-if="data.timeFrame">{{selectList.timeFrameList.find(item=>item.value===data.timeFrame).label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('overtimeApplication.field.time_unit')" v-if="data.timeUnit">{{selectList.timeUnitList.find(item=>item.value===data.timeUnit).label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('overtimeApplication.field.status')" v-if="data.status">{{this.selectList.statusList.find(item=>item.value=== this.data.status).label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('overtimeApplication.field.reject_reason')" v-if="data.rejectReason">{{data.rejectReason}}</DescriptionsItem>
  </Descriptions>
  <Col :span="12" style="text-align:left;margin-bottom:10px;">
            <Button type="danger" v-if="data.status === 1 && ((!data.approveTime && data.approverId === this.$store.getters.getId) || (!data.approve2Time && data.approver2Id === this.$store.getters.getId))" @click="reject">{{$t('leaveApprove.name.reject')}}</Button>&nbsp;
                <Button type="danger" v-if="data.status === 1 && ((!data.approveTime && data.approverId === this.$store.getters.getId) || (!data.approve2Time && data.approver2Id === this.$store.getters.getId))" @click="approve">{{$t('leaveApprove.name.pass')}}</Button>
          </Col>
  <Descriptions :title="$t('overtimeApplication.name.historyApplication')">
      </Descriptions>
  
      <Row>
        <Col :span="24" >
        <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="8" class="text-right">
                <!-- <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>&nbsp; -->
                <!-- <Button type="danger" v-if="data.status === 1" @click="reject">{{$t('leaveApprove.name.reject')}}</Button>&nbsp;
                <Button type="danger" v-if="data.status === 1" @click="approve">{{$t('leaveApprove.name.pass')}}</Button> -->
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
          <Table
            :columns="columns"
            :dataSource="list"
            :scroll="{ x: true }"
            rowKey="idOveApp"
            :loading="loading"
            :pagination="false"
          >
          </Table>
        </Col>
      </Row>
    </Col>
  </Row>
  </LayoutContent>
  </Layout>
  <OvertimeApplicationReject ref="refOvertimeApplicationReject" @updateList="loadData"></OvertimeApplicationReject>
</template>

<script>
import { createVNode } from "vue";
import {
  Descriptions,
  DescriptionsItem,
  Row,
  Col,
  Table,
  Image,
  message,
  Typography,
  Layout,
  Form,
  Input, 
  Select, 
  RangePicker,
  Button,
  Modal,
  Card,
} from "ant-design-vue";
import { LoadingOutlined, PlusOutlined,ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { OvertimeApplicationGet } from "@/api/OvertimeApplicationGet";
import { OvertimeApplicationApproveNew } from "@/api/OvertimeApplicationApproveNew";
import { OvertimeApplicationGetHistoryApplication } from "@/api/OvertimeApplicationGetHistoryApplication";
import OvertimeApplicationReject from "@/components/Home/OvertimeApplicationReject";
import LanguageButton from "@/components/Common/LanguageButton";
import moment from "moment";

export default {
  name: "LeaveApprove",
  components: {
    Layout,
    LoadingOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Descriptions,
    DescriptionsItem,
    TypographyTitle: Typography.Title,
    Image,
    Row,
    Col,
    Table,
    message,
    Form,
    FormItem: Form.Item,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    RangePicker,
    Modal,
    Card,
    OvertimeApplicationReject,
    LanguageButton,
  },
  data() {
    return {
      idLea: null,
      rangePickerDate:["",""],
      searchForm: {
        idLeaTyp: null,
        // leaveType: null,
        startDate: null,
        endDate: null,
      },
      visable:false,
      data: {},
      remainingVacation: {},
      selectList: {
        leaveTypeList: [],
        // leaveTypeList: [
        //   { value: 1, label: "調休" },
        //   { value: 2, label: "年假" },
        //   { value: 3, label: "產假" },
        //   { value: 4, label: "侍產假" },
        //   { value: 5, label: "事假" },
        //   { value: 6, label: "病假" },
        //   { value: 7, label: "婚假" },
        // ],
        timeFrameList: [{value: 1,label: 'AM'},{value: 2,label: 'PM'}],
        timeUnitList: [{value: 0.25,label: '0.25'},{value: 0.50,label: '0.50'},{value: 1.00,label: '1.00'}],
        statusList: [{value: 0,label: '待發送'},{value: 1,label: '待審批'},{value: 2,label: '已拒絕'},{value: 3,label: '已通過'}],
      },
      loading: false,
      list: [],
      columns: [
        { title: this.$t("overtimeApplication.table.id_ove_app"), fixed: "left", dataIndex: "idOveApp", key: "idOveApp" },
          { title: this.$t("overtimeApplication.table.id_emp"), dataIndex: "employeeNameTw", key: "employeeNameTw" },
          { title: this.$t("overtimeApplication.table.start_time"), dataIndex: "startTime", key: "startTime", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
          // { title: this.$t("overtimeApplication.table.end_time"), dataIndex: "endTime", key: "endTime", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
          { title: this.$t("overtimeApplication.table.duration"), dataIndex: "duration", key: "duration" },
          // { title: this.$t("overtimeApplication.table.approver_id"), dataIndex: "approverId", key: "approverId" },
          // { title: this.$t("overtimeApplication.table.approve_time"), dataIndex: "approveTime", key: "approveTime" },
          { title: this.$t("overtimeApplication.table.reason"), dataIndex: "reason", key: "reason" },
          { title: this.$t("overtimeApplication.table.submit_time"), dataIndex: "submitTime", key: "submitTime", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD HH:mm:ss')}},
          { title: this.$t("overtimeApplication.table.time_remark"), dataIndex: "timeRemark", key: "timeRemark" },
          // { title: this.$t("overtimeApplication.table.time_frame"), dataIndex: "timeFrame", key: "timeFrame", customRender: ({text}) =>{
          //     if(text){
          //       return this.selectList.timeFrameList.find(item=>item.value===text).label
          //     }
              
          //   }
          // },
          // { title: this.$t("overtimeApplication.table.time_unit"), dataIndex: "timeUnit", key: "timeUnit", customRender: ({text}) =>{
          //     return this.selectList.timeUnitList.find(item=>item.value===text).label
          //   }
          // },
          { title: this.$t("overtimeApplication.table.status"), dataIndex: "status", key: "status", customRender: ({text}) =>{
              return this.selectList.statusList.find(item=>item.value===text).label
            }
          },
        { key: "action", align: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
    };
  },
  computed: {},
  mounted() {
    this.idOveApp = this.$route.params.idOveApp
    this.loadData();
  },
  methods: {
    loadData() {
      let api = new OvertimeApplicationGet();
      api
        .get({
          idOveApp: this.idOveApp,
        })
        .then((response, any) => {
          if (0 == parseInt(response.data.code)) {
            this.data = response.data.data;
            //判定当前登录者是否有审批权限
            if(this.data.approverId !== this.$store.getters.getId && this.data.approver2Id !== this.$store.getters.getId){
              message.error(this.$t("public.name.no_permission"));
              this.$router.push({ name: "Dashboard" });
              return;
            }else{
              this.visable = true;
            }


            this.get_list();
          } else {
            message.error(response.data.msg);
          }
        });
    },
    reject(){
      this.$refs.refOvertimeApplicationReject.show(this.idOveApp);
    },
    approve(){
      Modal.confirm({
        title: this.$t("overtimeApplication.name.are_you_sure_pass") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new OvertimeApplicationApproveNew();
          api.post({ idOveApp: this.idOveApp }).then((response) => {
            if (0 === parseInt(response.data.code)) {
              this.loadData()
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
        },
      });
    },
    get_list() {
      this.loading = true;
      const api = new OvertimeApplicationGetHistoryApplication();
      const getData = {
        idEmp: this.data.idEmp,
      };
      // if (this.searchForm.idLeaTyp != "") getData.idLeaTyp = this.searchForm.idLeaTyp
      // if (this.searchForm.startDate != "") getData.startDate = this.searchForm.startDate
      // if (this.searchForm.endDate != "") getData.endDate = this.searchForm.endDate
      api.get(getData).then((response) => {
        this.loading = false;
        if (0 === parseInt(response.data.code)) {
          const dataTmp = response.data;
          this.list = dataTmp.data;
        } else {
          this.list = [];
        }
      });
    },
    search_submit() {
      this.get_list()
    },
    search_reset() {
      this.rangePickerDate = ["",""],
      this.searchForm = {
        leaveType: null,
        startDate: null,
        endDate: null,
      },
      this.get_list()
    },
    rangeChange(dates){
      this.searchForm.startDate = dates[0].format("YYYY-MM-DD")
      this.searchForm.endDate = dates[1].format("YYYY-MM-DD")
    },
  },
};
</script>


<style lang="less">
@import url("~@/assets/less/overtimeApplicationApprove.less");
</style>