<template>
  <Row id="Leave">
    <Col :span="24" class="title">
      <TypographyTitle>{{$t('nav.name.LeaveManage')}}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="5">
                <FormItem :label="$t('leave.name.search')" name="search">
                  <Input :placeholder="$t('leave.name.search')" v-model:value="searchForm.search"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="5">
                <FormItem :label="$t('attendance.name.department')" name="department">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="searchForm.idDep"
                      >
                      <SelectOption :value="null">{{ $t('attendance.name.select_all') }}</SelectOption>
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['departmentList']" :value="item['idDep']">{{ item['name'] }}</SelectOption>
                      </Select>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="5">
                    <FormItem :label="$t('leave.field.id_lea_typ')" name="idLeaTyp">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="searchForm.idLeaTyp"
                              :placeholder="$t('leave.validate.required.id_lea_typ')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                                            <SelectOption :value="null">{{ $t('attendance.name.select_all') }}</SelectOption>
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['leaveTypeList']" :value="item['idLeaTyp']">{{ item['leaveTypeName'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
                <Col :xs="24" :sm="9">
                <FormItem :label="$t('attendance.name.dateRange')" name="dateRange">
                  <RangePicker :allowClear="false" v-model:value="rangePickerDate" @change="rangeChange"/>
                </FormItem>
              </Col>
              <Col span="10">
                    <FormItem :label="$t('leave.field.status')" name="status">
                      <Select
                              v-model:value="searchForm.status"
                              :placeholder="$t('leave.validate.required.status')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      <SelectOption :value="null">{{ $t('attendance.name.select_all') }}</SelectOption>
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['statusList']" :value="item['value']">{{ item['label'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
              <Col :xs="24" :sm="8" class="text-right">
                <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>&nbsp;
                <Button type="primary" @click="leave_report">{{$t('leave.name.leave_report')}}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
        <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" rowKey="idLea" :loading="loading" :pagination="tablePagination" @change="chang_page">
            <template #actionTitle>
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('LeaveManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
            </template>
            <template #action="{text}">
              <div>
                <!-- <div class="pc-display">
                  <Button type="default" size="small" @click="detail(text)">{{$t("public.name.detail")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('LeaveManage.Edit') && !record.approverId" type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('LeaveManage.Delete')" type="primary" danger size="small" @click="del(text.idLea)">{{$t("public.name.del")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('LeaveManage.Approve') && !record.approverId" type="primary" size="small" @click="approve(text)">{{$t("leave.name.approve")}}</Button>
                </div> -->
                <Dropdown :trigger="['click']">
                  <template #overlay>
                    <Menu>
                      <MenuItem @click="detail(text)">{{$t('public.name.detail')}}</MenuItem>
                      <MenuItem v-if="checkPermission('LeaveManage.Edit')" @click="edit(text)">{{$t('public.name.edit')}}</MenuItem>
                      <MenuItem v-if="checkPermission('LeaveManage.Delete')" danger @click="del(text.idLea)">{{$t('public.name.del')}}</MenuItem>
                      <!-- <MenuItem v-if="checkPermission('LeaveManage.Approve') && !record.approverId" danger @click="approve(text)">{{$t("leave.name.approve")}}</MenuItem> -->
                    </Menu>
                  </template>
                  <Button size="small">
                    {{$t("public.name.more")}}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <LeaveDetail ref="refLeaveDetail"></LeaveDetail>
    <LeaveEdit ref="refLeaveEdit" @updateList="get_list"></LeaveEdit>
    <LeaveApprove ref="refLeaveApprove" @updateList="get_list"></LeaveApprove>
    <DownloadLeaveReport ref="refDownloadLeaveReport"></DownloadLeaveReport>
  </Row>
</template>

<script>
import { createVNode } from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message,RangePicker } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { LeaveList } from "@/api/LeaveList";
import { LeaveDelete } from "@/api/LeaveDelete"
import LeaveDetail from "@/components/Home/LeaveDetail";
import LeaveEdit from "@/components/Home/LeaveEdit";
import LeaveApprove from "@/components/Home/LeaveApprove";
import DownloadLeaveReport from "@/components/Home/DownloadLeaveReport";
import { DepartmentListAll } from "@/api/DepartmentListAll";
import { LeaveTypeListAll } from "@/api/LeaveTypeListAll"
import moment from "moment";

export default {
  name: "LeaveManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    LeaveDetail,
    LeaveEdit,
    LeaveApprove,
    RangePicker,
    DownloadLeaveReport,
  },
  data() {
    return {
      rangePickerDate:[null,null],
      searchForm: {
        search: '',
        idDep: null,
        idLeaTyp: null,
        startDate: null,
        endDate: null,
        status: null,
      },
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
          { title: this.$t("leave.table.id_lea"), fixed: "left", dataIndex: "idLea", key: "idLea" },
          { title: this.$t("leave.table.id_emp"), dataIndex: "employeeNickName", key: "employeeNickName" },
          { title: this.$t("leave.table.id_lea_typ"), dataIndex: "leaveTypeLeaveTypeName", key: "leaveTypeLeaveTypeName" },
          // { title: this.$t("leave.table.leave_type"), dataIndex: "leaveType", key: "leaveType", customRender: ({text}) =>{
          //     return this.selectList.leaveTypeList.find(item=>item.value===text).label
          //   }
          // },
          { title: this.$t("leave.table.start_time"), dataIndex: "startTime", key: "startTime", customRender: ({text,record}) =>{
              if(record.leaveType === 2 || record.leaveType === 3 || record.leaveType === 4){
                return moment(text).format('YYYY-MM-DD')
              }else {
                return moment(text).format('YYYY-MM-DD')
              }
            }
          },
          { title: this.$t("leave.table.end_time"), dataIndex: "endTime", key: "endTime", customRender: ({text,record}) =>{
              if(record.leaveType === 2 || record.leaveType === 3 || record.leaveType === 4){
                return moment(text).format('YYYY-MM-DD')
              }else {
                return moment(text).format('YYYY-MM-DD')
              }
            }
          },
          { title: this.$t("leave.table.duration"), dataIndex: "duration", key: "duration", customRender: ({text,record}) =>{
              // var days =  Math.floor(text/480);
              // var hours =  Math.floor(text%480/60);
              // var minutes =  text%60;
              // var result = '';
              // if(days !== 0){
              //   result = days + '天';
              // }
              // if(hours !== 0){
              //   result = result + hours + "小時"
              // }
              // if(minutes !== 0){
              //   result = result + minutes + "分鐘"
              // }
              // return result;
              return text + '天'
            }
          },
          // { title: this.$t("leave.table.reason"), dataIndex: "reason", key: "reason" },
          // { title: this.$t("leave.table.picture_url"), dataIndex: "pictureUrl", key: "pictureUrl" },
          { title: this.$t("leave.table.status"), dataIndex: "status", key: "status", customRender: ({text,record}) =>{
              return this.selectList.statusList.find(item=>item.value===text).label
            }
          },
          { title: this.$t("leave.table.approver_id"), dataIndex: "approverNickName", key: "approverNickName" },
          { title: this.$t("leave.table.approve_time"), dataIndex: "approveTime", key: "approveTime" },
          { title: this.$t("leave.table.approver2_id"), dataIndex: "approver2NickName", key: "approver2NickName" },
          { title: this.$t("leave.table.approve2_time"), dataIndex: "approve2Time", key: "approve2Time" },
          // { title: this.$t("leave.table.cc"), dataIndex: "cc", key: "cc" },
          // { title: this.$t("leave.table.remark"), dataIndex: "remark", key: "remark" },
          // { title: this.$t("leave.table.reject_reason"), dataIndex: "rejectReason", key: "rejectReason" },
          { title: this.$t("leave.table.submit_time"), dataIndex: "submitTime", key: "submitTime", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD HH:mm:ss')}},
          { title: this.$t("leave.table.time_frame"), dataIndex: "timeFrame", key: "timeFrame", customRender: ({text}) =>{
              if(text){
                return this.selectList.timeFrameList.find(item=>item.value===text).label
              }    
            }
          },
        { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      selectList:{
        employeeList: [],
        leaveTypeList: [],
        departmentList: [],
        //leaveTypeList: [{value: 1,label: '調休'},{value: 2,label: '年假'},{value: 3,label: '產假'},{value: 4,label: '侍產假'},{value: 5,label: '事假'},{value: 6,label: '病假'},{value: 7,label: '婚假'}],
        statusList: [{value: 0,label: '待發送'},{value: 1,label: '待審批'},{value: 2,label: '已拒絕'},{value: 3,label: '已通過'}],
	timeFrameList: [{value: 1,label: 'AM'},{value: 2,label: 'PM'}],
      }
    };
  },
  methods: {
    chang_page(pagination) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new LeaveList();
      const getData = {
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      }
      if (this.searchForm.search != "") getData.search = this.searchForm.search
      if (this.searchForm.idDep != 0) getData.idDep = this.searchForm.idDep
      if (this.searchForm.idLeaTyp != "") getData.idLeaTyp = this.searchForm.idLeaTyp
      if (this.searchForm.startDate != "") getData.startDate = this.searchForm.startDate
      if (this.searchForm.endDate != "") getData.endDate = this.searchForm.endDate
      getData.status = this.searchForm.status
      api
        .get(getData)
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            const dataTmp = response.data.data;
            this.tablePagination.current = parseInt(dataTmp.current);
            this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
            this.tablePagination.total = parseInt(dataTmp.total);
            this.list = dataTmp.data;
            //如果當前處於不是第一頁，但是又沒有數據的情況，則把頁面設置為1，並重新請求
            if(this.list.length == 0 && this.tablePagination.current != 1){
              this.tablePagination.current = 1;
              this.get_list();
            }
          } else {
            this.list = [];
          }
        });
    },
    add() {
      this.$refs.refLeaveEdit.addShow();
    },
    detail(obj) {
      this.$refs.refLeaveDetail.detailShow(obj);
    },
    edit(obj) {
      this.$refs.refLeaveEdit.editShow(obj);
    },
    leave_report(){
      this.$refs.refDownloadLeaveReport.show();
    },
    del(idLea) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new LeaveDelete();
          api.post({ idLea: idLea }).then((response) => {
            if (0 === parseInt(response.data.code)) {
              this.get_list();
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
        },
      });
    },
    approve(obj) {
      this.$refs.refLeaveApprove.detailShow(obj);
    },
    getDepartmentList() {
      const api = new DepartmentListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.departmentList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    getLeaveTypeList() {
      const api = new LeaveTypeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.leaveTypeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    rangeChange(dates){
      this.searchForm.startDate = dates[0].format("YYYY-MM-DD")
      this.searchForm.endDate = dates[1].format("YYYY-MM-DD")
    },
    search_submit() {
      this.get_list()
    },
    search_reset() {
      this.rangePickerDate = [null,null];
      this.searchForm = {
        search: '',
        idDep: null,
        idLeaTyp: null,
        startDate: null,
        endDate: null,
        status: null,
      };
      this.get_list()
    }
  },
  mounted() {
    this.get_list();
    this.getDepartmentList();
    this.getLeaveTypeList();
  },
}
</script>

<style lang="less">
@import url("~@/assets/less/home/leave.less");
</style>